import React, { useState } from "react";
import { getAccount, createAccount, updateAccount } from "api/accounts";
import EntityCreateUpdate from "containers/entity-create-update";
import SalesAccountForm from "components/forms/sales-account";
import useNotifications from "components/notifications";

const EditSalesAccountContainer = ({ id }) => {
  const { notifySuccess } = useNotifications();

  const [submittedValues, setSubmittedValues] = useState(undefined);

  return (
    <EntityCreateUpdate
      id={id}
      form={SalesAccountForm}
      get={getAccount}
      create={createAccount}
      update={updateAccount}
      entityName="ACCOUNT"
      onSubmitted={result => {
        setSubmittedValues(result);
        notifySuccess("Account updated.");
        window.location.href = "/";
      }}
      submittedValues={submittedValues}
    />
  );
};

export default EditSalesAccountContainer;
