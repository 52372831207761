import { get, post, patch } from "./";
import endpoints from "../constants/endpoints";

const endpoint = endpoints.users;

export async function setAccount({ id }) {
  return post(`${endpoint}/set-account/${id}`);
}

export async function unsetAccount() {
  return post(`${endpoint}/unset-account`);
}

export async function login(data) {
  return post(`${endpoint}/login`, data);
}

export async function register(data) {
  return post(`${endpoint}/register`, data);
}

export async function getCurrentUser() {
  return get(`${endpoint}/me`);
}

export async function updateCurrentUser(data) {
  return patch(`${endpoint}/me`, data);
}
