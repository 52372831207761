import React, { useRef } from "react";
import styles from "./slug-field.module.css";

const SlugField = ({
  id,
  field,
  form,
  autoComplete,
  placeholder,
  readOnly,
  baseUrl = "fixr.co/organiser/"
}) => {
  const inputRef = useRef(null);

  return (
    <div className={styles.container}>
      <input
        readOnly={readOnly}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={inputRef}
        className={styles.field}
        id={id}
        type="text"
        value={field.value}
        onChange={e => {
          form.setFieldValue(field.name, e.target.value);
        }}
      ></input>
      <div
        onClick={() => {
          inputRef.current.focus();
        }}
        className={styles.baseUrl}
      >
        {baseUrl}
      </div>
    </div>
  );
};

export default SlugField;
