export const getComponentsInPage = components => {
  let componentsInPage = [];

  [...document.querySelectorAll(`[data-component]`)].forEach(el => {
    const componentKey = el.getAttribute("data-component");

    if (components[componentKey]) {
      let props = {};
      try {
        props = JSON.parse(el.getAttribute("data-props"));
      } catch (e) {
        console.warn(
          `Could not parse the data-props passed to component "${componentKey}":`
        );
        console.warn(el.getAttribute("data-props"));
        console.warn(`The component will render without props.`);
      }
      componentsInPage.push({
        el,
        component: components[componentKey],
        props
      });
    } else {
      console.warn(
        `A DOM element is referencing a non-existing React component "${componentKey}". Check spelling or implement/register component.`
      );
      console.warn(el);
    }
  });

  return componentsInPage;
};
