import React from "react";
import { createAccount } from "api/accounts";
import FormContainer from "components/form-container";
import SalesAccountForm from "components/forms/sales-account-basic";
import { submitForm } from "utils/form";
import tagManager from "utils/tag-manager";

const SalesAccountContainer = () => {
  return (
    <FormContainer>
      <SalesAccountForm
        onSubmit={async (values, formikBag) => {
          const result = await submitForm(createAccount)(values, formikBag);
          if (result) {
            tagManager.push({ event: tagManager.events.salesAccountCreated });
            window.location.href = "/";
          }
        }}
      />
    </FormContainer>
  );
};

export default SalesAccountContainer;
