import React from "react";
import TextButton from "components/button/text";
import styles from "./form-field-reset.module.css";

const FormFieldReset = ({ onClick, children }) => {
  return (
    <div className={styles.container} data-element="form-field-reset">
      <TextButton onClick={onClick}>{children}</TextButton>
    </div>
  );
};

export default FormFieldReset;
