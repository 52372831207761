import React from "react";
import Checkbox from "components/checkbox";
import styles from "./boolean-field.module.css";

const BooleanField = ({ field, form, label, id, ...props }) => {
  return (
    <div className={styles.container} data-value={`${field.value}`}>
      <Checkbox
        id={id}
        onChange={(e) => {
          form.setFieldValue(field.name, e.target.checked);
        }}
        checked={field.value}
        label={label}
        {...props}
      />
    </div>
  );
};

export default BooleanField;
