import React from "react";
import classnames from "classnames";
import Button from "../";
import styles from "./icon.module.css";

const IconButton = ({ className = "", children, icon, ...props }) => {
  return (
    <Button
      className={classnames({ [styles.icon]: true, [className]: className })}
      {...props}
    >
      {children}
      {icon}
    </Button>
  );
};

export default IconButton;
