import React from "react";

const UserIcon = ({ color = "#282E34" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M9 1.556c1.716 0 3.113 1.384 3.113 3.08 0 1.7-1.397 3.08-3.113 3.08s-3.113-1.38-3.113-3.08c0-1.696 1.397-3.08 3.113-3.08zM9 9.27c2.584 0 4.686-2.079 4.686-4.635C13.686 2.079 11.584 0 9 0S4.315 2.08 4.315 4.636 6.416 9.27 9 9.27zm7.427 9.173H1.573v-3.609c0-.912.361-1.804.966-2.39.31-.3.819-.664 1.546-.643.401.01 1.644-.005 3.155-.01 2.257-.01 5.104-.026 6.577-.026 1.793 0 2.61 1.446 2.61 2.79v3.888zm-2.61-8.234c-1.474 0-4.325.016-6.585.026-1.5.005-2.739.01-3.125.01H4.1c-.974-.015-1.922.368-2.663 1.09-.9.87-1.438 2.177-1.438 3.5v4.386c0 .425.352.778.786.778h16.428a.785.785 0 0 0 .786-.778v-4.667c0-2.105-1.466-4.345-4.183-4.345z"
      />
    </svg>
  );
};

export default UserIcon;
