export const mapFormErrorsFromApi = response => {
  let errors = {};
  if (response.detail) {
    Object.keys(response.detail).forEach(key => {
      if (response.detail[key][0]) {
        errors[key] = response.detail[key][0];
      } else {
        errors[key] = mapFormErrorsFromApi({ detail: response.detail[key] });
      }
    });
  }
  return errors;
};

export const submitForm = (apiFunc, redirectUrl = null) => async (
  values,
  formikBag
) => {
  const { setErrors, setSubmitting, setStatus } = formikBag;

  try {
    setStatus(null);
    const result = await apiFunc({ ...values });

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
    return result;
  } catch (e) {
    setSubmitting(false);
    setErrors(mapFormErrorsFromApi(e));
    setStatus(
      e.message ||
        "An error occured. Please try again or contact FIXR if the problem persists."
    );
    return false;
  }
};
