import React, { Component } from 'react';
import Tab from './components/tab';
import PropTypes from 'prop-types';
import './styles.css';

export { Tab };

export default class Tabs extends Component {
	static propTypes = {
		onChange: PropTypes.func,
		defaultActiveTabIndex: PropTypes.number
	};

	static defaultProps = {
		onChange: PropTypes.onChange,
		defaultActiveTabIndex: 0
	};

	constructor(props) {
		super(props);
		this.state = {
			activeTabIndex: this.props.defaultActiveTabIndex
		};
	}

	setActiveIndex = (index, value) => {
		if (index !== this.state.activeTabIndex) {
			this.setState({
				activeTabIndex: index
			});
			this.props.onChange(index, value);
		}
	};

	renderHeader = () => {
		return React.Children.map(this.props.children, (child, index) => {
			return (
				<Tab
					isActive={index === this.state.activeTabIndex}
					tabIndex={index}
					onClick={() => {
						this.setActiveIndex(index, child.props.value);
					}}
				>
					{child.props.title}
				</Tab>
			);
		});
	};

	renderActiveTabContent = () => {
		const { children } = this.props;
		const { activeTabIndex } = this.state;
		if (children[activeTabIndex]) {
			return children[activeTabIndex].props.children;
		}
	};

	render() {
		return (
			<div className="Tabs">
				<React.Fragment>{this.renderHeader()}</React.Fragment>
				<div className="tabs-active-content">
					<span style={{ color: 'red' }}>{this.renderActiveTabContent()}</span>
				</div>
			</div>
		);
	}
}
