import React, { useState, useEffect, useRef, useMemo } from "react";
import classnames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import parse from "date-fns/parse";
import addMinutes from "date-fns/addMinutes";
import styles from "./time-picker.module.css";

const BASE_DATETIME = parseISO("1970-01-01T00:00");
const MINUTES_IN_A_DAY = 60 * 24;

const parseTime = timeString => {
  if (timeString === "") return null;

  if (timeString.length >= 4 && !isNaN(timeString)) {
    const d = new Date(BASE_DATETIME);
    d.setHours(timeString[0] + timeString[1]);
    d.setMinutes(timeString[2] + timeString[3]);
    return d;
  }

  var time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
  if (time == null) return null;

  var hours = parseInt(time[1], 10);
  if (hours === 12 && !time[4]) {
    hours = 0;
  } else {
    hours += hours < 12 && time[4] ? 12 : 0;
  }
  const d = new Date(BASE_DATETIME);
  d.setHours(hours);
  d.setMinutes(parseInt(time[3], 10) || 0);
  d.setSeconds(0, 0);
  return d;
};

const TimePicker = ({
  onChange,
  selected,
  placeHolder = "HH:MM",
  locale = "en-GB",
  minTime,
  maxTime,
  timeIntervals = 15
}) => {
  const [inputValue, setInputValue] = useState(selected || "");
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const selectedRef = useRef(null);
  const listRef = useRef(null);
  const minTimeAsDate = minTime
    ? parse(minTime, "HH:mm", BASE_DATETIME)
    : BASE_DATETIME;
  const maxTimeAsDate = maxTime
    ? parse(maxTime, "HH:mm", BASE_DATETIME)
    : addMinutes(BASE_DATETIME, MINUTES_IN_A_DAY - 1);

  const times = useMemo(
    () =>
      [...Array(MINUTES_IN_A_DAY / timeIntervals)]
        .map((e, i) =>
          format(addMinutes(BASE_DATETIME, i * timeIntervals), "HH:mm")
        )
        .concat("23:59"),
    [timeIntervals]
  );

  useEffect(() => {
    if (dropdownIsOpen && selectedRef.current) {
      listRef.current.scrollTop = selectedRef.current.offsetTop;
    }
  }, [dropdownIsOpen]);

  return (
    <div className={styles.container}>
      <input
        placeholder={placeHolder}
        value={inputValue}
        autoComplete="off"
        type="text"
        onChange={e => {
          setInputValue(e.target.value);
        }}
        onBlur={() => {
          setDropdownIsOpen(false);

          if (inputValue !== selected) {
            const parsed = parseTime(inputValue);
            if (!parsed) {
              setInputValue("");
              onChange("");
            } else {
              const timeString = format(parsed, "HH:mm");
              setInputValue(timeString);
              onChange(timeString);
            }
          }
        }}
        onFocus={() => {
          setDropdownIsOpen(true);
        }}
      />
      <AnimatePresence>
        {dropdownIsOpen && (
          <motion.div
            ref={listRef}
            className={styles.dropdown}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
          >
            <ul>
              {times.map((timeString, i) => {
                const scrollTo = inputValue || minTime || "14:00";
                const disabled =
                  parse(timeString, "HH:mm", BASE_DATETIME) < minTimeAsDate ||
                  parse(timeString, "HH:mm", BASE_DATETIME) > maxTimeAsDate;

                return (
                  <li
                    className={classnames({
                      [styles.time]: true,
                      [styles.disabled]: disabled
                    })}
                    ref={timeString === scrollTo ? selectedRef : null}
                    key={i}
                    onMouseDown={() => {
                      if (!disabled) {
                        setInputValue(timeString);
                        onChange(timeString);
                      }
                    }}
                  >
                    {timeString}
                  </li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default TimePicker;
