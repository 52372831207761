import React from 'react';
import classnames from 'classnames';
import './styles.css';

export const Column = ({ children, span }) => (
	<div className={classnames({ column: true, [`is-${span}`]: !!span })}>
		{children}
	</div>
);

export const Columns = ({ children }) => (
	<div className="columns">{children}</div>
);
