import React, { useState, useEffect, useRef, useMemo } from "react";
import shortid from "shortid";
import Checkbox from "components/checkbox";
import HelpButton from "components/button/help";
import styles from "./form-field-toggle.module.css";

const FormFieldToggle = ({
  initiallyVisible = false,
  hasErrors = false,
  help,
  label,
  onHide = () => {},
  onShow = () => {},
  focusOnShow = false,
  children
}) => {
  const [visible, setVisible] = useState(initiallyVisible);
  const id = useMemo(() => "toggle-" + shortid.generate(), []);
  const childrenRef = useRef(null);

  useEffect(() => {
    if (hasErrors) {
      setVisible(true);
    }
  }, [hasErrors]);

  useEffect(() => {
    if (visible && focusOnShow) {
      const firstInput = childrenRef.current.querySelector("input");
      firstInput && firstInput.focus();
    }
  }, [visible, focusOnShow, childrenRef]);

  return (
    <div className={styles.container} data-element="field-toggle">
      <div className={styles.field}>
        <Checkbox
          id={id}
          label={label}
          checked={visible}
          onChange={e => {
            const checked = e.target.checked;
            if (checked) {
              onShow();
            } else {
              onHide();
            }
            setVisible(checked);
          }}
        />
        {help && <HelpButton>{help}</HelpButton>}
      </div>
      <div className={styles.children} ref={childrenRef}>
        {visible && children}
      </div>
    </div>
  );
};

export default FormFieldToggle;
