import React from "react";
import DateOutput, { dateFormats } from "components/date-output";
import Card from "components/card";
import { Columns, Column } from "components/grid";
import PrettyAmount from "components/pretty-amount";
import ProgressBar from "components/progress-bar";
import "./styles.css";
import Auth from "components/auth";

const EventListItem = ({ event }) => {
  const { revenue } = event;
  //TODO: Get from API when/if it's implemented
  const preferredCurrency = Object.keys(revenue).reduce((a, b) =>
    revenue[a] > revenue[b] ? a : b
  );

  return (
    <Card color={event.orderIndexColour}>
      <a href={event.cmsUrl} className="event-list-item">
        <Columns>
          <Column span="8">
            <h2 className="event-list-item__title">{event.name}</h2>
            <div className="event-list-item__date">
              <DateOutput
                date={event.openTime}
                timeZone={event.timezone}
                format={dateFormats.EVENT_TIME_FULL}
              />
            </div>
            <div className="event-list-item__venue">
              <DateOutput
                format={dateFormats.EVENT_TIME_HOURS}
                timeZone={event.timezone}
                date={event.openTimeVenueLocalised}
              />{" "}
              at {event.venue.name}
            </div>
          </Column>
          <Column span="4">
            <div className="event-list-item__stats">
              <h3>Tickets Sold</h3>
              <h4 className="event-list-item__tickets-sold">
                {event.ticketsSold} <span>of {event.ticketsTotal}</span>
              </h4>
              <ProgressBar
                disabled={
                  event.salesStatusName === "Sold_Out" ||
                  event.salesStatusName === "Expired"
                }
                value={event.ticketsSold}
                max={event.ticketsTotal}
              />
              <Auth permissions="sales_account_events_manage">
                <div className="event-list-item__revenue">
                  <h3>Revenue</h3>
                  <h4>
                    {revenue[preferredCurrency] ? (
                      <PrettyAmount
                        currency={preferredCurrency}
                        amount={revenue[preferredCurrency]}
                      />
                    ) : (
                      "–"
                    )}
                  </h4>
                </div>
              </Auth>
            </div>
          </Column>
        </Columns>
      </a>
    </Card>
  );
};

export default EventListItem;
