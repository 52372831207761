import React from "react";
import Register from "components/register";
import { register } from "api/users";
import { submitForm } from "utils/form";
import useNextParam from "hooks/use-next-param";

const RegisterContainer = props => {
  const nextParam = useNextParam();

  return (
    <Register
      {...props}
      onSubmit={async (values, formikBag) => {
        const captchaToken = window.grecaptcha
          ? await window.grecaptcha.execute()
          : "";

        submitForm(register, nextParam)({ ...values, captchaToken }, formikBag);
      }}
    />
  );
};

export default RegisterContainer;
