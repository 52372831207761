import React from "react";
import classnames from "classnames";
import styles from "./button.module.css";
import "./legacy.css";

const Button = React.forwardRef(
  (
    { children, className = "", type = "button", element = "button", ...props },
    ref
  ) => {
    return React.createElement(
      element,
      {
        className: classnames({
          [styles.button]: true,
          [className]: className
        }),
        ref,
        type,
        ...props
      },
      children
    );
  }
);

export default Button;
