import React, { useState, useRef, useEffect } from "react";
import Portal from "../portal";
import styles from "./tooltip.module.css";

const Tooltip = ({
  verticalAlign = "top",
  horisontalAlign = "center",
  margin = 5,
  content,
  onShow = () => {},
  onHide = () => {},
  children
}) => {
  const ref = useRef(null);
  const tooltipRef = useRef(null);
  const mounted = useRef(false);
  const [isVisible, setIsVisible] = useState(false);
  const [boundingRect, setBoundingRect] = useState(null);

  const handleShouldHide = () => setIsVisible(false);

  useEffect(() => {
    if (isVisible) {
      window.addEventListener("click", handleShouldHide);
      window.addEventListener("scroll", handleShouldHide);
      window.addEventListener("resize", handleShouldHide);
      setBoundingRect(ref.current.getBoundingClientRect());
    }

    return () => {
      window.removeEventListener("click", handleShouldHide);
      window.removeEventListener("scroll", handleShouldHide);
      window.removeEventListener("resize", handleShouldHide);
    };
  }, [isVisible]);

  useEffect(() => {
    if (mounted.current) {
      if (isVisible) {
        onShow();
      } else {
        onHide();
      }
    } else {
      mounted.current = true;
    }
  }, [isVisible, mounted, onShow, onHide]);

  const getPosition = () => {
    const tooltipBoundingRect = tooltipRef.current.getBoundingClientRect();

    const alignVertically = alignment => {
      const documentOffsetY = Math.abs(document.documentElement.offsetTop);
      switch (alignment) {
        case "top":
          return (
            documentOffsetY +
            window.scrollY +
            boundingRect.top -
            margin -
            tooltipBoundingRect.height
          );
        case "bottom":
          return (
            documentOffsetY +
            window.scrollY +
            boundingRect.top +
            boundingRect.height +
            margin
          );

        default:
          return 0;
      }
    };
    const alignHorisontally = alignment => {
      switch (alignment) {
        case "left":
          return boundingRect.left;

        case "right":
          return (
            boundingRect.left + boundingRect.width - tooltipBoundingRect.width
          );

        case "center":
          return (
            boundingRect.left +
            boundingRect.width / 2 -
            tooltipBoundingRect.width / 2
          );

        default:
          return 0;
      }
    };

    let top = alignVertically(verticalAlign);
    let left = alignHorisontally(horisontalAlign);

    if (left < window.scrollX) {
      left = boundingRect.left;
    } else if (
      left + tooltipBoundingRect.width >
      window.scrollX + window.innerWidth
    ) {
      left =
        window.innerWidth -
        tooltipBoundingRect.width -
        (window.innerWidth - boundingRect.right);
    }

    if (
      top + tooltipBoundingRect.height >
      window.scrollY + window.innerHeight
    ) {
      top = alignVertically("top");
    } else if (top < window.scrollY) {
      top = alignVertically("bottom");
    }

    return {
      left,
      top
    };
  };

  return (
    <div
      ref={ref}
      onClick={() => {
        setIsVisible(!isVisible);
      }}
    >
      {children}
      <Portal>
        {isVisible && (
          <div
            ref={tooltipRef}
            onClick={e => {
              setIsVisible(false);
            }}
            style={
              tooltipRef.current && isVisible
                ? { zIndex: 999, ...getPosition() }
                : { visibility: "hidden" }
            }
            className={styles.tooltip}
          >
            <div className={styles.content}>{content}</div>
          </div>
        )}
      </Portal>
    </div>
  );
};

export default Tooltip;
