import React from "react";
import { connect, getIn } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./form-field-error.module.css";

const FormFieldError = ({ name, formik }) => {
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);

  const show = error && touched;

  return (
    <div className={styles.container}>
      <AnimatePresence>
        {show && (
          <motion.div
            key={name}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className={styles.error}>{error}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default connect(FormFieldError);
