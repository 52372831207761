import React, { useState, useEffect, useCallback, useRef } from "react";
import format from "date-fns/format";
import parse from "date-fns/parse";
import isSameDay from "date-fns/isSameDay";
import parseISO from "date-fns/parseISO";
import TimePicker from "components/time-picker";
import DatePicker from "components/date-picker";
import styles from "./date-time-field.module.css";

const DateTimeField = ({
  id,
  field,
  form,
  onChange = () => {},
  minDateIso,
  maxDateIso
}) => {
  const [dateString, setDateString] = useState(
    field.value ? field.value.split("T")[0] : null
  );
  const [timeString, setTimeString] = useState(
    field.value ? field.value.split("T")[1] : ""
  );
  const mounted = useRef(false);

  const memoisedOnChange = useCallback(onChange, []);
  const { setFieldValue } = form;

  useEffect(() => {
    if (mounted.current) {
      const isoDate = `${dateString}T${timeString}`;
      memoisedOnChange(isoDate, dateString, timeString);
      setFieldValue(field.name, dateString && timeString ? isoDate : null);
    }
  }, [dateString, timeString, memoisedOnChange, setFieldValue, field.name]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const dateWithoutTime = dateString
    ? parse(dateString, "yyyy-MM-dd", new Date())
    : null;

  const minDate = minDateIso ? parseISO(minDateIso) : null;
  const maxDate = maxDateIso ? parseISO(maxDateIso) : null;

  const minTime =
    minDate && dateString && isSameDay(minDate, dateWithoutTime)
      ? minDateIso.split("T")[1]
      : null;

  const maxTime =
    maxDate && dateString && isSameDay(maxDate, dateWithoutTime)
      ? maxDateIso.split("T")[1]
      : null;

  return (
    <div className={styles.container}>
      <div>
        <DatePicker
          minDate={minDate}
          maxDate={maxDate}
          id={id}
          selected={dateWithoutTime}
          onChange={date => {
            try {
              const dateString = format(date, "yyyy-MM-dd");
              if (dateString.length <= 10) {
                setDateString(dateString);
              } else {
                setDateString(null);
              }
            } catch (e) {
              setDateString(null);
            }
          }}
        />
      </div>
      <div>at</div>
      <div className={styles.timeContainer}>
        <TimePicker
          minTime={minTime}
          maxTime={maxTime}
          selected={timeString}
          onChange={timeString => {
            setTimeString(timeString);
          }}
        />
      </div>
    </div>
  );
};

export default DateTimeField;
