import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

const useOnResize = (debounceTime = 600) => {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const onResize = debounce(() => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }, debounceTime);

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [debounceTime]);

  return size;
};

export default useOnResize;
