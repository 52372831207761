import React from "react";
import { useState, useEffect } from "react";
import { getEntryConditions } from "api/entry-conditions";
import useNotifications from "components/notifications";

const useEntryConditions = () => {
  const [entryConditions, setEntryConditions] = useState([]);
  const [entryConditionsOptions, setEntryConditionsOptions] = useState([]);
  const { notifyError } = useNotifications();

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getEntryConditions();
        setEntryConditions(result.data);
        setEntryConditionsOptions(
          result.data.map(c => ({
            value: c.id,
            text: c.name,
            data: {
              iconUrl: c.iconUrl
            }
          }))
        );
      } catch (e) {
        notifyError(
          <>
            Could not fetch entry conditions.{" "}
            <button onClick={fetch}>Retry?</button>
          </>
        );
      }
    };

    fetch();
  }, [notifyError]);

  return { entryConditions, entryConditionsOptions };
};

export default useEntryConditions;
