import React from "react";
import posed from "react-pose";

const Container = posed.div({
  empty: { opacity: 0, scale: 1.5 },
  fill: { opacity: 1, scale: 1, delay: 50, transition: { duration: 600 } }
});

const Circle = posed.circle({
  empty: { opacity: 1 },
  fill: { opacity: 1 }
});

const Check = posed.path({
  empty: { pathLength: 0 },
  fill: { pathLength: 1, delay: 500 }
});

const AnimatedSuccessIcon = ({ show = true }) => {
  return (
    <Container initialPose="empty" pose={show ? "fill" : "empty"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70"
        height="70"
        viewBox="0 0 70 70"
      >
        <Circle
          cx="35"
          cy="35"
          r="24"
          stroke="#7f7f7f"
          strokeWidth="3"
          strokeLinecap="round"
          fill="transparent"
        />
        <Check
          stroke="#7f7f7f"
          strokeWidth="3"
          d="M23 34 34 43 47 27"
          fill="transparent"
        />
      </svg>
    </Container>
  );
};

export default AnimatedSuccessIcon;
