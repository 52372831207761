import React from "react";
import { createVenue } from "api/venues";
import FormContainer from "components/form-container";
import VenueForm from "components/forms/venue";
import { submitForm } from "utils/form";

const CreateVenueContainer = ({ name, onSubmitted }) => {
  return (
    <FormContainer>
      <VenueForm
        initialValues={{ name, showVenue: true }}
        autoFocus
        onSubmit={async (values, formikBag) => {
          const result = await submitForm(createVenue)(values, formikBag);
          if (result) {
            onSubmitted(result);
          }
        }}
      />
    </FormContainer>
  );
};

export default CreateVenueContainer;
