import React from "react";
import classnames from "classnames";
import styles from "./form-progress.module.css";

const FormProgress = ({
  steps,
  currentStep = 0,
  showInactiveLabels = false
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        <div
          style={{
            transform: `translate3d(${-100 +
              (currentStep / (steps.length - 1)) * 100}%, 0, 0)`
          }}
          className={styles.barInner}
        />
      </div>
      <div className={styles.steps}>
        {steps.map((step, i) => (
          <div
            key={i}
            className={classnames({
              [styles.step]: true,
              [styles.past]: i < currentStep,
              [styles.current]: i === currentStep,
              [styles.inActive]: showInactiveLabels && i > currentStep
            })}
          >
            <div className={styles.label}>
              <span>{step}</span>
            </div>
            <div className={styles.circle} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormProgress;
