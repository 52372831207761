import React from "react";

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#282E34"
        fillRule="evenodd"
        d="M9.228 8l6.517-6.517A.868.868 0 1 0 14.517.255L8 6.77 1.483.255A.868.868 0 1 0 .255 1.483L6.77 8 .255 14.517a.868.868 0 1 0 1.228 1.228L8 9.228l6.517 6.517a.866.866 0 0 0 1.228 0 .868.868 0 0 0 0-1.228L9.228 8"
      />
    </svg>
  );
};

export default CloseIcon;
