import React from 'react';
import classnames from 'classnames';
import './styles.css';

const ProgressBar = ({ value, max, disabled = false }) => {
	return (
		<progress
			className={classnames({
				'Progress-bar': true,
				'Progress-bar--disabled': disabled
			})}
			value={value}
			max={max}
		/>
	);
};

export default ProgressBar;
