import React from "react";

const CrossIcon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="3" stroke="#FFF" fill="none" fillRule="evenodd">
      <path d="M2 1l17 17M2 18L19 1" />
    </g>
  </svg>
);

export default CrossIcon;
