import "whatwg-fetch";
import humps from "humps";
import Cookies from "js-cookie";
import { addBreadcrumb } from "utils/sentry";

const log = addBreadcrumb("apiClient");

const setHeaders = (additionalHeaders) => ({
  "content-type": "application/json",
  "FIXR-Platform-Version": navigator?.userAgent || navigator?.vendor,
  "FIXR-Platform": "organiser_django",
  "FIXR-App-Version": window?.__REACT_CONFIG__?.FIXR_VERSION,
  "X-CSRFTOKEN": Cookies.get(
    window?.__REACT_CONFIG__?.CSRF_COOKIE_NAME || "csrftoken"
  ),
  ...additionalHeaders,
});

const camelize = (obj) =>
  humps.camelizeKeys(obj, function (key, convert) {
    return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
  });

const decamelize = (obj) =>
  humps.decamelizeKeys(obj, function (key, convert, options) {
    return /^[A-Z0-9_]+$/.test(key) ? key : convert(key, options);
  });

const parse = async (result) => {
  log("PARSE");
  try {
    const data = camelize(await result.json());
    if (result.status >= 400) {
      return Promise.reject(data);
    }
    return data;
  } catch {
    throw new Error(
      result.statusText ||
        `Received status ${result.status} when getting data from server.`
    );
  }
};

export const get = async (url, headers) => {
  log("GET", { category: "ApiClient", data: { url } });
  const result = await fetch(url, {
    credentials: "same-origin",
    headers: setHeaders(headers),
  });
  return await parse(result);
};

export const post = async (url, postData, headers) => {
  log("POST", { category: "ApiClient", data: { url } });
  const result = await fetch(url, {
    credentials: "same-origin",
    body: JSON.stringify(decamelize(postData)),
    headers: setHeaders(headers),
    method: "POST",
  });

  return await parse(result);
};

export const postAsFormData = async (url, data = {}, headers) => {
  log("POST FORM DATA", { category: "ApiClient", data: { url } });
  const requestHeaders = setHeaders(headers);
  delete requestHeaders["content-type"];
  const formData = new FormData();
  Object.keys(decamelize(data)).forEach((key) => {
    formData.append(key, data[key]);
  });

  const result = await fetch(url, {
    credentials: "same-origin",
    body: formData,
    headers: requestHeaders,
    method: "POST",
  });

  return await parse(result);
};

export const put = async (url, putData, headers) => {
  log("PUT", { category: "ApiClient", data: { url } });
  const result = await fetch(url, {
    credentials: "same-origin",
    body: JSON.stringify(decamelize(putData)),
    headers: setHeaders(headers),
    method: "PUT",
  });

  return await parse(result);
};

export const patch = async (url, putData, headers) => {
  log("PATCH", { category: "ApiClient", data: { url } });
  const result = await fetch(url, {
    credentials: "same-origin",
    body: JSON.stringify(decamelize(putData)),
    headers: setHeaders(headers),
    method: "PATCH",
  });

  return await parse(result);
};

export const del = async (url, headers) => {
  log("DEL", { category: "ApiClient", data: { url } });
  const result = await fetch(url, {
    headers: setHeaders(headers),
    credentials: "same-origin",
    method: "DELETE",
  });

  if (result.status >= 400) {
    return Promise.reject("Could not delete.");
  }

  return result;
};
