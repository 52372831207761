import React from "react";
import BlockLoader from "components/block-loader";
import styles from "./field-loader.module.css";

const FieldLoader = () => {
  return (
    <div className={styles.container}>
      <BlockLoader />
      <input disabled type="text"></input>
    </div>
  );
};

export default FieldLoader;
