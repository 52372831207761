import React from "react";
import FormProgress from "components/form-progress";
import FormContainer from "components/form-container";
import SlidingPanels, { Panel } from "components/sliding-panels";
import SalesAccountBasicForm from "components/forms/sales-account-basic";
import RegisterForm from "components/forms/register";
import SecondaryButton from "components/button/secondary";
import EventForm from "components/forms/event";
import LinkButton from "components/button/link";
import styles from "./onboarding.module.css";
import FormSeparator from "components/form-separator";
import FormPage from "components/form-page";
import useExperiment from "components/ab-testing";
import useConfig from "hooks/use-config";

const Onboarding = ({
  currentStep,
  steps,
  captchaSiteKey,
  onSubmitRegister,
  onSubmitSalesAccount,
  onSubmitEvent
}) => {
  const experiment =
    useConfig().OPTIMIZE_AB_TEST_MAPPING.onboarding_registration_header_2 || {};

  const [A, B] = useExperiment(
    experiment.experiment_id,
    experiment.activation_event
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <FormContainer wide>
          <A>
            <FormProgress currentStep={currentStep} steps={steps} />
          </A>
          <B>
            <FormProgress
              showInactiveLabels
              currentStep={currentStep}
              steps={steps}
            />
          </B>
        </FormContainer>
      </div>
      <FormPage
        header={
          <>
            <SlidingPanels activeIndex={currentStep}>
              <Panel>
                <h3>Welcome to FIXR Organiser!</h3>
                <p>
                  Before you can create your event, let us know who you are.
                </p>
              </Panel>
              <Panel>
                <h3>Create sales account</h3>
                <p>
                  Set up your new sales account. You’ll be able to create an
                  event in a minute.
                </p>
              </Panel>
              <Panel>
                <h3>Create event</h3>
                <p>
                  Set up an event now or <a href="/">do it later</a>.
                </p>
              </Panel>
            </SlidingPanels>
          </>
        }
      >
        <SlidingPanels activeIndex={currentStep}>
          <Panel>
            <FormContainer>
              <RegisterForm
                captchaSiteKey={captchaSiteKey}
                onSubmit={onSubmitRegister}
                autoFocus={currentStep === 0}
              />
              <FormSeparator>Already a FIXR user?</FormSeparator>
              <LinkButton
                style={{ marginTop: 0 }}
                buttonElement={SecondaryButton}
                href="/user/login"
              >
                SIGN IN
              </LinkButton>
            </FormContainer>
            <div className={styles.disclaimer}>
              By creating a FIXR event management account, you are agreeing to
              our{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://fixr.co/legal/customer-terms-and-conditions"
              >
                Terms and Conditions{" "}
              </a>
            </div>
          </Panel>
          <Panel>
            <FormContainer>
              <SalesAccountBasicForm
                onSubmit={onSubmitSalesAccount}
                autoFocus={currentStep === 1}
              />
            </FormContainer>
          </Panel>
          <Panel>
            <FormContainer>
              <EventForm onSubmit={onSubmitEvent}></EventForm>
            </FormContainer>
          </Panel>
        </SlidingPanels>
      </FormPage>
    </div>
  );
};

export default Onboarding;
