import { useCallback } from "react";
import useNotifications from "components/notifications";

const fallback = text => {
  const input = document.createElement("input");
  input.style.position = "absolute";
  input.style.opacity = "0";
  input.value = text;
  input.setAttribute("readonly", "true");
  document.body.appendChild(input);
  input.select();
  const sel = window.getSelection();
  sel.removeAllRanges();
  const range = document.createRange();
  sel.addRange(range);
  input.setSelectionRange(0, text.length);
  document.execCommand("copy");
  document.body.removeChild(input);
};

const useCopyToClipboard = () => {
  const { notifySuccess, notifyError } = useNotifications();

  const CopyToClipboard = useCallback(
    async (text = "", message = "Copied to clipboard.") => {
      try {
        await navigator.clipboard.writeText(text);
        if (message) {
          notifySuccess(message);
        }
      } catch (e) {
        try {
          fallback(text);
          notifySuccess(message);
        } catch (e) {
          notifyError("Could not copy to clipboard.");
        }
      }
    },
    [notifySuccess, notifyError]
  );

  return CopyToClipboard;
};

export default useCopyToClipboard;
