import React, { useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Portal from "components/portal";
import useScrollLock from "hooks/use-scroll-lock";
import classnames from "classnames";
import styles from "./modal.module.css";

const defaultAnimations = {
  initial: {
    opacity: 0,
    y: "10%"
  },
  enter: {
    opacity: 1,
    y: "0%",
    transition: { type: "spring", stiffness: 180, damping: 18 }
  },
  exit: {
    opacity: 0,
    y: "0%",
    transition: { duration: 0.2 }
  }
};

const Modal = ({
  isOpen,
  lockScrollWhenOpen = true,
  animations = {},
  className,
  onOutsideClicked = () => {},
  children
}) => {
  const { setScrollLocked } = useScrollLock();
  const outsideRef = useRef(null);

  const handleOutsideClicked = e => {
    if (e.target === outsideRef.current) {
      onOutsideClicked();
    }
  };

  useEffect(() => {
    if (isOpen && lockScrollWhenOpen) {
      setScrollLocked(true);
    }
  }, [isOpen, lockScrollWhenOpen, setScrollLocked]);

  const { initial, enter, exit } = { ...defaultAnimations, ...animations };

  return (
    <Portal
      className={classnames({ [styles.portal]: true, [className]: className })}
    >
      <AnimatePresence
        onExitComplete={() => {
          if (lockScrollWhenOpen) {
            setScrollLocked(false);
          }
        }}
      >
        {isOpen && [
          <motion.div
            className={styles.backdrop}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.35 } }}
            exit={{ opacity: 0, transition: { duration: 0.2, delay: 0.1 } }}
            key="backdrop"
            data-element="modal-backdrop"
          />,
          <motion.div
            ref={outsideRef}
            className={styles.container}
            onClick={handleOutsideClicked}
            initial={initial}
            animate={enter}
            exit={exit}
            key="outside"
            data-element="modal-container"
          >
            {children}
          </motion.div>
        ]}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
