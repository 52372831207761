import { useEffect } from "react";
import useNotifications from "components/notifications";

const Notify = ({
  message = "",
  category = "info",
  timeout = 0,
  delay = 0,
  tag = ""
}) => {
  const { notify } = useNotifications();

  useEffect(() => {
    notify(message, { category, timeout, delay, tag });
  }, [notify, message, category, delay, timeout, tag]);

  return null;
};

export default Notify;
