import PropTypes from "prop-types";
import useConfig from "hooks/use-config";

export const hasPermission = (
  userPermissions,
  permissionStringOrArrayOrRegex
) => {
  const isRegex =
    Object.prototype.toString.call(permissionStringOrArrayOrRegex) ===
    "[object RegExp]";

  if (isRegex) {
    return userPermissions.some(permission =>
      permission.match(permissionStringOrArrayOrRegex)
    );
  }

  return []
    .concat(permissionStringOrArrayOrRegex)
    .some(permission => userPermissions.includes(permission));
};

export const useAuth = () => {
  const { USER_PERMISSIONS = [] } = useConfig();

  return {
    permissions: USER_PERMISSIONS,
    hasPermission: permissionStringOrArray =>
      hasPermission(USER_PERMISSIONS, permissionStringOrArray)
  };
};

export const Auth = ({ children = null, nope = null, permissions }) => {
  const { hasPermission } = useAuth();

  return hasPermission(permissions) ? children : nope;
};

Auth.propTypes = {
  children: PropTypes.node,
  nope: PropTypes.node,
  permissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.instanceOf(RegExp)
  ])
};

export default Auth;
