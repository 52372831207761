import React from "react";
import BlockLoader, { Line } from "components/block-loader";
import Fieldset from "components/form-fieldset";
import styles from "./form-loading.module.css";

const FormLoading = () => {
  return (
    <div className={styles.container} data-element="form-loading">
      <Fieldset legend={<Line style={{ height: "24px", width: "70%" }}></Line>}>
        <div className={styles.lines}>
          <Line
            style={{ height: "16px", width: "50%", marginBottom: "0" }}
          ></Line>
          <Line style={{ height: "50px" }}></Line>
          <Line
            style={{ height: "16px", width: "30%", marginBottom: "0" }}
          ></Line>
          <Line style={{ height: "50px" }}></Line>
          <Line
            style={{ height: "16px", width: "45%", marginBottom: "0" }}
          ></Line>
          <Line style={{ height: "120px" }}></Line>
          <Line
            style={{ height: "16px", width: "35%", marginBottom: "0" }}
          ></Line>
          <Line style={{ height: "50px" }}></Line>
        </div>
      </Fieldset>
      <BlockLoader />
    </div>
  );
};

export default FormLoading;
