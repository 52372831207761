import React from "react";
import { getHub, getHubs } from "api/hubs";
import EntityAutoCompleteField from "components/entity-auto-complete-field";

const HubsField = ({ placeholder, form, field, id }) => {
  return (
    <EntityAutoCompleteField
      multiSelect
      id={id}
      field={field}
      form={form}
      placeholder={placeholder}
      fetchEntity={getHub}
      fetchData={getHubs}
    ></EntityAutoCompleteField>
  );
};

export default HubsField;
