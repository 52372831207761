import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import Field from "components/form-field";
import PlacesAutocomplete from "components/places-autocomplete";
import LocationField from "components/location-field";
import BooleanField from "components/boolean-field";
import ImageField from "components/image-field";
import Form from "components/form";
import styles from "./venue.module.css";

const VenueSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  mapPoint: Yup.object()
    .shape({
      latitude: Yup.string(),
      longtitude: Yup.string(),
    })
    .nullable()
    .required("Required. Drag pin to set location."),
});

const defaultValues = {
  name: "",
  logo: "",
  address: "",
  area: "",
  city: "",
  country: "",
  postcode: "",
  mapPoint: null,
  showVenue: true,
};

const VenueForm = ({
  initialValues = defaultValues,
  onSubmit,
  autoFocus = false,
}) => {
  const [locationEditable, setLocationEditable] = useState(false);
  const [showAdditionalFields, setshowAdditionalFields] = useState(
    !!initialValues.address
  );
  const firstFieldRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => firstFieldRef.current.focus(), 0);
    }
  }, [autoFocus]);

  return (
    <Form
      testId="venue-form"
      stopPropagation
      initialValues={initialValues}
      validationSchema={VenueSchema}
      onSubmit={onSubmit}
      render={({ setFieldValue }) => {
        return (
          <>
            <Field
              innerRef={(ref) => (firstFieldRef.current = ref)}
              autoFocus={autoFocus}
              placeholder="The name of the venue"
              name="name"
              type="text"
              label="NAME"
              autoComplete="off"
            />

            {showAdditionalFields ? (
              <div>
                <div className={styles.map}>
                  <Field
                    readonly={!locationEditable}
                    label="LOCATION"
                    badge={locationEditable ? "Drag pin to set location" : null}
                    hideLabel={!locationEditable}
                    name="mapPoint"
                    component={LocationField}
                    onResetClicked={() => {
                      setFieldValue("address", "");
                      setFieldValue("city", "");
                      setFieldValue("area", "");
                      setFieldValue("postcode", "");
                      setFieldValue("country", "");
                      setFieldValue("mapPoint", null);
                      setshowAdditionalFields(false);
                    }}
                  />
                </div>
                <Field
                  name="address"
                  type="text"
                  label="ADDRESS"
                  testId="address"
                />
                <Field name="city" type="text" label="CITY" testId="city" />
                <Field
                  name="area"
                  optional
                  type="text"
                  label="AREA"
                  testId="area"
                />
                <Field
                  name="postcode"
                  optional
                  type="text"
                  label="POSTCODE"
                  testId="postcode"
                />
                <Field
                  name="country"
                  type="text"
                  label="COUNTRY"
                  testId="country"
                />
              </div>
            ) : (
              <Field
                testId="address"
                name="address"
                usePortal={false}
                label="ADDRESS"
                onSelect={(place) => {
                  setFieldValue("address", place.address);
                  setFieldValue("city", place.city);
                  setFieldValue("area", place.area);
                  setFieldValue("postcode", place.postCode);
                  setFieldValue("country", place.country);
                  if (place.latitude && place.longitude) {
                    setFieldValue("mapPoint.latitude", place.latitude);
                    setFieldValue("mapPoint.longitude", place.longitude);
                    setLocationEditable(false);
                  } else {
                    setFieldValue("mapPoint", null);
                    setLocationEditable(true);
                  }
                  setshowAdditionalFields(true);
                }}
                component={PlacesAutocomplete}
              />
            )}
            <Field
              testId="showVenue"
              inline
              label="Venue is public"
              hideLabel
              name="showVenue"
              component={BooleanField}
              help="A public venue will be searchable on the FIXR website."
            />
            <Field
              testId="logo"
              name="logo"
              label="VENUE LOGO"
              optional
              component={ImageField}
              minWidth={100}
              minHeight={100}
              aspectRatio={1}
              imageType="Venue_Logo"
            />
          </>
        );
      }}
    />
  );
};

export default VenueForm;
