import React from "react";
import Login from "components/login";
import { login } from "api/users";
import { submitForm } from "utils/form";
import useNextParam from "hooks/use-next-param";

const LoginContainer = props => {
  const nextParam = useNextParam();

  return (
    <Login
      nextParam={nextParam}
      {...props}
      onSubmit={(values, formikBag) => {
        submitForm(login, nextParam)(values, formikBag);
      }}
    />
  );
};

export default LoginContainer;
