import React from "react";
import * as Yup from "yup";
import Field from "components/form-field";
import PhoneField from "components/phone-field";
import ImageField from "components/image-field";
import Form from "components/form";
import Fieldset from "components/form-fieldset";
import { isValidPhoneNumber } from "react-phone-number-input";

const SalesAccountSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  mobileNumber: Yup.string()
    .required("Required: we may need to contact you about your event.")
    .test(
      "valid-phone-number",
      "Please enter a valid phone number: we may need to contact you about your event.",
      (value) => isValidPhoneNumber(value) || !value
    ),
});

const defaultValues = {
  name: "",
  mobileNumber: "",
  website: "",
  facebookUrl: "",
  twitterUrl: "",
  logoId: null,
};

const SalesAccountBasicForm = ({
  initialValues = defaultValues,
  onSubmit,
  autoFocus = false,
}) => {
  return (
    <Form
      testId="sales-account-basic-form"
      initialValues={initialValues}
      validationSchema={SalesAccountSchema}
      onSubmit={onSubmit}
      render={() => (
        <>
          <Fieldset>
            <Field
              autoFocus={autoFocus}
              name="name"
              type="text"
              label="ORGANISER NAME"
              autoComplete="new-organiser"
            />
            <Field
              name="mobileNumber"
              type="tel"
              label="MOBILE NUMBER"
              autoComplete="tel"
              component={PhoneField}
              placeholder="+447911123456"
            />
            <Field
              name="logoId"
              label="ORGANISER LOGO"
              component={ImageField}
              minWidth={100}
              minHeight={100}
              aspectRatio={1}
              imageType="Venue_Logo"
            />
          </Fieldset>
          <Fieldset legend="Website and social">
            <Field
              placeholder="https://example.com"
              optional
              name="website"
              type="url"
              label="WEBSITE"
            />
            <Field
              placeholder="https://facebook.com/mypage"
              optional
              name="facebookUrl"
              type="url"
              label="FACEBOOK PAGE"
            />
            <Field
              placeholder="https://twitter.com/myusername"
              optional
              name="twitterUrl"
              type="url"
              label="TWITTER ACCOUNT"
            />
          </Fieldset>
        </>
      )}
    />
  );
};

export default SalesAccountBasicForm;
