import React, { useState, useEffect, useCallback, useRef } from "react";
import debounce from "lodash/debounce";
import useGoogleMapsApi from "hooks/use-google-maps-api";
import FieldLoader from "components/field-loader";
import AutoComplete from "components/auto-complete";
import { DropdownItem, DropdownExtraItem } from "components/input-dropdown";
import TextButton from "components/button/text";
import styles from "./places-autocomplete.module.css";

let autoCompleteService = null;
let placesService = null;

const defaultValues = {
  address: "",
  city: "",
  area: "",
  postCode: "",
  country: "",
  latitude: null,
  longitude: null
};

const PlaceAutocomplete = ({ onSelect, onChange, usePortal }) => {
  const { apiLoaded, google } = useGoogleMapsApi();
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(null);
  const attributionRef = useRef(null);

  useEffect(() => {
    if (apiLoaded && google) {
      autoCompleteService = new google.maps.places.AutocompleteService(
        attributionRef.current
      );

      placesService = new google.maps.places.PlacesService(
        attributionRef.current
      );
    }
  }, [apiLoaded, google]);

  const handleReceiveSuggestions = result => {
    if (result) {
      setSuggestions(
        result.map(item => ({
          value: item.place_id,
          text: item.description,
          data: item
        }))
      );
    }
    setLoadingSuggestions(false);
  };

  const getSuggestions = useCallback(
    debounce(searchTerm => {
      autoCompleteService.getQueryPredictions(
        { input: searchTerm },
        handleReceiveSuggestions
      );
    }, 250),
    []
  );

  return apiLoaded ? (
    <div>
      <AutoComplete
        loading={loadingSuggestions}
        minCharacters={1}
        clientSideFiltering={false}
        placeholder="Search addresses"
        suggestions={suggestions}
        usePortal={usePortal}
        onChange={value => {
          onChange && onChange(value);
          if (value) {
            setLoadingSuggestions(true);
            getSuggestions(value);
          }
        }}
        onSelect={item => {
          const request = {
            placeId: item.value,
            fields: [
              "address_components",
              "formatted_address",
              "geometry.location"
            ]
          };

          placesService.getDetails(request, res => {
            const data = res.address_components;
            const getField = name => {
              const field = data.find(d => d.types.includes(name));
              return field ? field.long_name : "";
            };

            onSelect({
              address: res.formatted_address.split(",")[0],
              city: getField("postal_town"),
              area:
                getField("administrative_area_level_1") ||
                getField("administrative_area_level_2"),
              postCode: getField("postal_code"),
              country: getField("country"),
              latitude: res.geometry.location.lat(),
              longitude: res.geometry.location.lng()
            });
          });
        }}
        renderItem={item => (
          <DropdownItem className={styles.item}>{item.text}</DropdownItem>
        )}
      >
        <DropdownExtraItem>
          <TextButton onMouseDown={() => onSelect(defaultValues)}>
            Manually enter details
          </TextButton>
        </DropdownExtraItem>
      </AutoComplete>
      <div className={styles.autoAttribution} ref={attributionRef}></div>
    </div>
  ) : (
    <FieldLoader />
  );
};

export default PlaceAutocomplete;

// var service = new google.maps.places.AutocompleteService();
// service.getQueryPredictions({ input: 'pizza near Syd' }, displaySuggestions);

// get details: service.getDetails(request, callback);

// var request = {
//   placeId: 'ChIJN1t_tDeuEmsRUsoyG83frY4',
//   fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
// };
// fields=address_component,opening_hours,geometry

// https://developers.google.com/maps/documentation/javascript/places#place_details_requests

// service = new google.maps.places.PlacesService(map);
// service.getDetails(request, callback);

// function callback(place, status) {
//   if (status == google.maps.places.PlacesServiceStatus.OK) {
//     createMarker(place);
//   }
// }
