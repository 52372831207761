import { useState, useEffect, useCallback } from "react";

const waitForVariantTimeout = 2000;

const useExperiment = (id, activationEvent) => {
  const [variant, setVariant] = useState(null);

  const VariantA = useCallback(({ children }) => variant === "A" && children, [
    variant
  ]);
  const VariantB = useCallback(({ children }) => variant === "B" && children, [
    variant
  ]);

  useEffect(() => {
    if (typeof window !== "undefined" && window["gtag"]) {
      window["gtag"]("event", "optimize.callback", {
        name: id,
        callback: variant => {
          setVariant(variant === "1" ? "B" : "A");
        }
      });
      window.dataLayer && window.dataLayer.push({ event: activationEvent });
    } else {
      console.warn("A/B Testing: Could not find window.gtag, rendering A.");
      console.warn(
        `Make sure the Optimize code snippet is properly installed.`
      );
      setVariant("A");
    }
  }, [id, activationEvent]);

  useEffect(() => {
    let timeout = null;
    if (!variant) {
      timeout = setTimeout(() => {
        setVariant("A");
        console.warn(
          `A/B Testing: Experiment not applied. Make sure there is an active Optimize container with experiment name ${id}`
        );
        console.warn(
          `A/B Testing: Timed out waiting for variant on experiment ${id}. Rendering A.`
        );
      }, waitForVariantTimeout);
    }

    return () => clearTimeout(timeout);
  }, [variant, id]);

  return [VariantA, VariantB, variant];
};

export default useExperiment;
