import React, { useState } from "react";
import { connect } from "formik";
import Portal from "components/portal";
import styles from "./form-debug.module.css";

const FormDebug = ({ formik }) => {
  const { values, errors } = formik;
  const [expanded, setExpanded] = useState(true);

  return (
    <Portal className={styles.portal}>
      {expanded && (
        <>
          <h3>Values</h3>
          <pre>
            {JSON.stringify(values, (k, v) => (v === undefined ? null : v), 2)}
          </pre>
          <h3>Errors</h3>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
        </>
      )}
      <button className={styles.expand} onClick={() => setExpanded(!expanded)}>
        {expanded ? "-" : "+"}
      </button>
    </Portal>
  );
};

export default connect(FormDebug);
