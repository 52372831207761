import React from "react";

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="10"
    viewBox="0 0 14 10"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#979797"
      strokeLinecap="round"
      strokeWidth="2"
      d="M1 1l6 6.75L13 1"
    />
  </svg>
);

export default ChevronDownIcon;
