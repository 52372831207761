import React from "react";

import styles from "./select.module.css";

const Select = ({ field, form, children, ...props }) => {
  return (
    <div className={styles.select}>
      <div className={styles.icon}>
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            transform="rotate(90 803.5 22.590909)"
            points="781 815 781 825.181818 789 820.090938"
            fill="#282E34"
            fillRule="evenodd"
          />
        </svg>
      </div>
      <select {...field} {...props}>
        {children}
      </select>
    </div>
  );
};

export default Select;
