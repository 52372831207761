import React, { useRef } from "react";
import PropTypes from "prop-types";
import InputField from "components/input-field";
import CopyButton from "components/button/copy";
import useCopyToClipboard from "hooks/use-copy-to-clipboard";
import "./styles.css";

const CopyToClipboard = ({
  value = "",
  buttonText = "Copy",
  confirmMessage = "Text copied to clipboard"
}) => {
  const copyToClipboard = useCopyToClipboard();
  const inputRef = useRef(null);

  const handleCopyClicked = () => {
    copyToClipboard(value, confirmMessage);
    inputRef.current.select();
  };

  return (
    <div className="copy-to-clipboard">
      <InputField ref={inputRef} value={value} readOnly />
      <CopyButton onClick={handleCopyClicked}>{buttonText}</CopyButton>
    </div>
  );
};

CopyToClipboard.propTypes = {
  value: PropTypes.string,
  buttonText: PropTypes.string,
  confirmMessage: PropTypes.string
};

export default CopyToClipboard;
