import React from "react";
import ResetPasswordForm from "components/forms/reset-password";
import FormContainer from "components/form-container";
import FormPage from "components/form-page";
import LinkButton from "components/button/link";
import FormSeparator from "components/form-separator";
import SecondaryButton from "components/button/secondary";
import FormSubmitSuccess from "components/form-submit-success";

const ResetPassword = ({ onSubmit, submitted = false }) => {
  return (
    <FormPage
      header={
        <>
          <h2>Forgot your password?</h2>
          <p>Enter your email address to reset your password.</p>
        </>
      }
    >
      <FormContainer>
        {submitted ? (
          <FormSubmitSuccess>
            An email with instructions has been sent to your email.
          </FormSubmitSuccess>
        ) : (
          <>
            <ResetPasswordForm onSubmit={onSubmit} />
            <FormSeparator />
            <LinkButton buttonElement={SecondaryButton} href="/">
              CANCEL
            </LinkButton>
          </>
        )}
      </FormContainer>
    </FormPage>
  );
};

export default ResetPassword;
