import React from "react";
import RadioButton from "components/radio-button";
import styles from "./radio-group-field.module.css";

const RadioGroupField = ({
  value,
  label,
  id,
  onChange,
  options = [],
  ...props
}) => {
  return (
    <div className={styles.container}>
      {options.map((o, i) => {
        return (
          <RadioButton
            key={o}
            id={id + i}
            value={o}
            onChange={onChange}
            checked={value === o}
            {...props}
          >
            {o}
          </RadioButton>
        );
      })}
    </div>
  );
};

export default RadioGroupField;
