import React from "react";

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="43"
    height="22"
    viewBox="0 0 43 22"
  >
    <defs>
      <linearGradient id="a" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stopColor="#CC003E" />
        <stop offset="100%" stopColor="#FF2000" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path fill="url(#a)" d="M0 0h43v22H0z" />
      <g fill="#fff">
        <path d="M5.957 6.186h7.336L13.28 8.23H8.52v2.184h4.395v2.044H8.52V16H5.956zM14.46 6.186h2.563V16H14.46zM18.246 6.186h2.982l1.834 2.786 1.848-2.786h3.024l-3.234 4.69L28.214 16h-3.066l-2.086-3.038L21.018 16h-2.996l3.486-4.998zM35.148 16l-1.428-2.716H31.816V16h-2.562V6.186h4.41q1.946 0 3.01.91 1.078.896 1.078 2.534 0 1.106-.448 1.918-.448.798-1.302 1.246L38.046 16zm-3.332-4.76h1.862q.784 0 1.204-.392.434-.392.434-1.134 0-.714-.434-1.092-.42-.392-1.204-.392h-1.862z" />
      </g>
    </g>
  </svg>
);

export default Logo;
