import React, { useEffect, useState, useMemo, useCallback } from "react";
import debounce from "lodash/debounce";
import TextButton from "components/button/text";
import { DropdownItem, DropdownExtraItem } from "components/input-dropdown";
import useNotfications from "components/notifications";
import Place from "components/place";
import AutoComplete from "components/auto-complete";
import SlideInModal from "components/modal/variants/slide-in";
import CreateVenue from "containers/create-venue";
import { getVenues, getVenue } from "api/venues";
import ErrorBoundary from "components/error-boundary";
import useImageApi from "hooks/use-image-api";

const VenueField = ({ field, form, onChange, onVenueLoaded, isOnline }) => {
  const { notifyError, notifySuccess } = useNotfications();
  const [venueSuggestionsLoading, setVenueSuggestionsLoading] = useState(false);
  const [isCreateVenueModalOpen, setCreateVenueModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [venueSuggestions, setVenueSuggestions] = useState([]);
  const [venue, setVenue] = useState(null);
  const [venueLogo, setVenueLogo] = useState(null);
  const { getImageById } = useImageApi();

  const value = field.value;

  useEffect(() => {
    const fetchVenue = async (id) => {
      let logo = null;
      let result = null;
      try {
        result = await getVenue(id);
        logo = await getImageById(result.logo);
      } catch (e) {
        notifyError(
          <>
            {e.message || e.detail}{" "}
            <button onClick={() => fetchVenue(id)}>Retry?</button>
          </>
        );
      } finally {
        if (result) {
          setVenue(result);
          onVenueLoaded(result);
          logo && setVenueLogo(logo.image);
        }
      }
    };
    if (value) {
      fetchVenue(value);
    }
  }, [value, onVenueLoaded, getImageById, notifyError]);

  const handleSearchTermChange = useCallback(
    debounce(async (value) => {
      setVenueSuggestionsLoading(true);
      const result = await getVenues(value, isOnline ? 1 : 0);
      setVenueSuggestions(
        result.data.map((venue) => ({
          value: venue.id,
          text: venue.name,
          data: venue,
        }))
      );
      setVenueSuggestionsLoading(false);
    }, 400),
    [isOnline]
  );

  useEffect(() => {
    if (isOnline) {
      handleSearchTermChange("");
    }
    setVenueSuggestions([]);
    setSearchTerm("");
  }, [isOnline, handleSearchTermChange]);

  const position = useMemo(() => {
    if (venue) {
      return {
        lat: venue.mapPoint.latitude,
        lng: venue.mapPoint.longitude,
      };
    }
    return null;
  }, [venue]);

  return (
    <ErrorBoundary>
      {value ? (
        <Place
          loading={!venue && !venueLogo}
          details={isOnline && venue ? { name: venue.name } : { ...venue }}
          position={position}
          image={venueLogo}
          showMap={!isOnline}
          onResetClicked={() => {
            onChange(null);
            setVenue(null);
            setVenueLogo(null);
            form.setFieldValue(field.name, null);
            setSearchTerm("");
          }}
        />
      ) : (
        <AutoComplete
          value={searchTerm}
          loading={venueSuggestionsLoading}
          placeholder={`Search for ${isOnline ? "timezones" : "venues"}`}
          suggestions={venueSuggestions}
          minCharacters={isOnline ? 0 : 1}
          renderItem={(item) => (
            <DropdownItem>
              {isOnline ? (
                item.data.name
              ) : (
                <>
                  {item.data.name || item.data.address}
                  {item.data.city && `, ${item.data.city}`}
                </>
              )}
            </DropdownItem>
          )}
          onChange={(value) => {
            if (value.trim() !== "") {
              setVenueSuggestionsLoading(true);
              handleSearchTermChange(value);
            }
            setSearchTerm(value);
          }}
          onSelect={(item) => {
            onChange(item.value);
            form.setFieldValue(field.name, item.value);
          }}
        >
          {!isOnline && (
            <DropdownExtraItem>
              <TextButton
                onMouseDown={(e) => {
                  setCreateVenueModalOpen(true);
                }}
              >
                Add new venue
              </TextButton>
            </DropdownExtraItem>
          )}
        </AutoComplete>
      )}
      <SlideInModal
        onCloseClicked={() => setCreateVenueModalOpen(false)}
        isOpen={isCreateVenueModalOpen}
      >
        <CreateVenue
          name={searchTerm}
          onSubmitted={(result) => {
            form.setFieldValue(field.name, result.id);
            setCreateVenueModalOpen(false);
            notifySuccess("Venue created.");
          }}
        />
      </SlideInModal>
    </ErrorBoundary>
  );
};

export default VenueField;
