import React from "react";
import classnames from "classnames";
import Circle from "components/icons/circle";
import styles from "./loader-dots.module.css";

const LoaderDots = props => (
  <span
    {...props}
    className={classnames({ [styles.container]: true, "loader-dots": true })}
    data-size={props.size || "medium"}
  >
    <span className={styles.loader} role="progressbar">
      <Circle circleClassName={styles.circle} />
      <Circle circleClassName={styles.circle} />
      <Circle circleClassName={styles.circle} />
    </span>
  </span>
);

export default LoaderDots;
