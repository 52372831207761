import React, { useState, useRef } from "react";
import LoaderDots from "components/loader-dots";
import InputDropdown from "components/input-dropdown";
import styles from "./auto-complete.module.css";

const AutoComplete = ({
  id,
  children,
  onChange,
  value,
  usePortal,
  defaultValue = "",
  suggestions = [],
  clientSideFiltering = false,
  minCharacters = 0,
  selectedItem: selectedItemFromProps = null,
  placeholder,
  renderItem,
  dropdownOffsetY,
  loading = false,
  onSelect = () => {}
}) => {
  const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);
  const inputValue = value || uncontrolledValue;
  const isControlled = value !== undefined;

  const [hasFocus, setHasFocus] = useState(false);
  const [selectedItem, setSelectedItem] = useState(selectedItemFromProps);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const filteredSuggestions = clientSideFiltering
    ? suggestions.filter(
        s => s.text.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      )
    : [...suggestions];

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  const handleItemSelected = item => {
    onSelect(item);
    setSelectedIndex(-1);
    setSelectedItem(item);

    if (!isControlled) {
      setUncontrolledValue(item.text);
    }
  };

  const onKeyDown = e => {
    switch (e.keyCode) {
      case 38: // up
        setSelectedIndex(
          selectedIndex <= 0
            ? filteredSuggestions.length - 1
            : selectedIndex - 1
        );

        break;

      case 40: // down
        setSelectedIndex(
          selectedIndex >= filteredSuggestions.length - 1
            ? 0
            : selectedIndex + 1
        );
        break;

      case 13: // enter
        e.preventDefault();
        if (selectedIndex > -1 && filteredSuggestions[selectedIndex]) {
          handleItemSelected(filteredSuggestions[selectedIndex]);
        }

        break;

      case 27: // esc
        inputRef.current.blur();
        break;

      default:
    }
  };

  const hideDropdown =
    !clientSideFiltering && loading && !filteredSuggestions.length;

  return (
    <div className={styles.container} ref={containerRef}>
      {loading && (
        <div className={styles.loader}>
          <LoaderDots size="small" />
        </div>
      )}
      <input
        id={id}
        placeholder={placeholder}
        ref={inputRef}
        value={inputValue}
        onChange={e => {
          onChange(e.target.value);
          setSelectedItem(null);
          setSelectedIndex(-1);
          if (!isControlled) {
            setUncontrolledValue(e.target.value);
          }
        }}
        type="text"
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
        onKeyDown={onKeyDown}
      />
      <InputDropdown
        offsetY={dropdownOffsetY}
        usePortal={usePortal}
        sizeRef={containerRef}
        isOpen={
          !hideDropdown &&
          inputValue.length >= minCharacters &&
          hasFocus &&
          selectedItem === null
        }
        items={filteredSuggestions}
        selectedIndex={selectedIndex}
        renderItem={renderItem}
        onSelect={item => {
          handleItemSelected(item);
        }}
      >
        {children}
      </InputDropdown>
    </div>
  );
};

export default AutoComplete;
