import React from "react";
import styles from "./form-separator.module.css";
import classnames from "classnames";

const FormSeparator = ({ children = null }) => {
  return (
    <div
      className={classnames({
        "form-separator": true,
        [styles.container]: true
      })}
    >
      <div className={styles.line} />
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default FormSeparator;
