import React from "react";

// In production this is set at runtime from Django settings
// in fixrcms/cms/templates/includes/scripts/react_config.html
const devConfig = {
  API_BASE: "",
  CMS_BASE: "https://organiser-goat.fixr.co",
  WEB_BASE: "https://web-goat.fixr.co",
  ENVIRONMENT: "goat",
  FACEBOOK_APP_ID: "1535232923394119",
  SENTRY_DSN: "https://dd277a71ba004cf1b1feaa02ce8bb1a6@sentry.io/153118",
  GOOGLE_API_KEY_BROWSER: "AIzaSyDMdDIfSSC1SdeUz493NcosyAuvuiKNT2M",
  USER_PERMISSIONS: [""],
  CURRENCIES: ["GBP", "EUR", "USD"],
  OPTIMIZE_AB_TEST_MAPPING: {}
};

const config = { ...devConfig, ...window.__REACT_CONFIG__ };
if (!window.__REACT_CONFIG__) {
  console.warn(
    "Config Provider: No client configuration object found. Using default dev config."
  );
}

const Context = React.createContext(config);

// Provider
export function ConfigProvider({ children }) {
  return <Context.Provider value={config}>{children}</Context.Provider>;
}

export default Context;
