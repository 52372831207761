import React, { useRef, useEffect } from "react";
import posed from "react-pose";
import styles from "./sliding-panels.module.css";

const transition = {
  type: "spring",
  stiffness: 140,
  damping: 21
};

const PanelPose = posed.div({
  visible: {
    opacity: 1,
    x: "0%",
    applyAtStart: {
      position: "relative",
      display: "block"
      // x: ({ activeIndex, previousIndex, translateX }) =>
      //   activeIndex === previousIndex
      //     ? 0
      //     : activeIndex > previousIndex
      //     ? `${translateX}`
      //     : `-${translateX}`
    },
    transition
  },
  hidden: {
    // x: ({ activeIndex, previousIndex, translateX }) =>
    //   activeIndex > previousIndex ? `-${translateX}` : `${translateX}`,
    applyAtStart: { position: "absolute", top: 0 },
    applyAtEnd: { position: "relative", display: "none" },
    opacity: 0,
    transition
  }
});

export const Panel = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

const SlidingPanels = ({ children, activeIndex = 0, translateX = "100%" }) => {
  const panels = [];

  React.Children.forEach(children, child => {
    if (child.type === Panel) {
      panels.push(child);
    } else {
      console.warn(
        "Only <Panel /> components are valid children for SlidingPanels component!"
      );
    }
  });

  const previousIndex = useRef(activeIndex);

  useEffect(() => {
    previousIndex.current = activeIndex;
  }, [activeIndex]);

  return (
    <div className={styles.panels}>
      {panels.map((panel, i) => (
        <PanelPose
          aria-hidden={activeIndex !== i}
          pose={activeIndex === i ? "visible" : "hidden"}
          activeIndex={activeIndex}
          previousIndex={previousIndex.current}
          key={i}
          className={styles.panel}
          translateX={translateX}
        >
          {panel}
        </PanelPose>
      ))}
    </div>
  );
};

export default SlidingPanels;
