import React from "react";
import { getEvent, createEvent, updateEvent } from "api/events";
import EntityCreateUpdate from "containers/entity-create-update";
import EventForm, { defaultValues } from "components/forms/event";

const EditEventContainer = ({ id, accountId }) => {
  const initialValues = accountId
    ? { ...defaultValues, salesAccount: accountId }
    : defaultValues;

  return (
    <EntityCreateUpdate
      id={id}
      initialValues={initialValues}
      form={EventForm}
      get={getEvent}
      create={createEvent}
      update={updateEvent}
      entityName="EVENT"
      onSubmitted={result => {
        window.location.href = `/events/${result.id}`;
      }}
    />
  );
};

export default EditEventContainer;
