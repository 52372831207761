import React from "react";
import {
  getBookingFeeScale,
  getBookingFeeScales
} from "api/booking-fee-scales";
import EntityAutoCompleteField from "components/entity-auto-complete-field";

const BookingFeeScaleFields = ({ placeholder, form, field, id }) => {
  return (
    <EntityAutoCompleteField
      id={id}
      field={field}
      form={form}
      placeholder={placeholder}
      fetchEntity={getBookingFeeScale}
      fetchData={getBookingFeeScales}
      minCharacters={0}
    ></EntityAutoCompleteField>
  );
};

export default BookingFeeScaleFields;
