import React from "react";
import SubmitButton from "../submit";
import styles from "./secondary.module.css";

const SecondaryButton = ({ className, ...props }) => {
  return (
    <SubmitButton {...props} className={styles.secondary}>
      {props.children}
    </SubmitButton>
  );
};

export default SecondaryButton;
