import { useState, useEffect, useRef } from "react";

const lockScroll = scrollTop => {
  const doc = document.documentElement;
  const scrollBarWidth =
    window.innerWidth - document.documentElement.clientWidth;
  doc.style.width = `calc(100% - ${scrollBarWidth}px)`;
  doc.style.position = "fixed";
  doc.style.top = `${-scrollTop}px`;
  doc.style.overflow = "hidden";
};

const unlockScroll = scrollTop => {
  const doc = document.documentElement;
  doc.style.width = "";
  doc.style.position = "";
  doc.style.top = "";
  doc.style.overflow = "";
  window.scroll(0, scrollTop);
};

const useScrollLock = () => {
  const [isScrollLocked, setScrollLocked] = useState(false);
  const scrollTop = useRef(window.pageYOffset);
  const firstRender = useRef(true);

  useEffect(() => {
    if (isScrollLocked) {
      scrollTop.current = window.pageYOffset;
      lockScroll(scrollTop.current);
    } else {
      if (!firstRender.current) {
        unlockScroll(scrollTop.current);
      }
      firstRender.current = false;
    }
  }, [isScrollLocked]);

  return { isScrollLocked, setScrollLocked };
};

export default useScrollLock;
