import React from "react";
import ReportError from "components/report-error";
import { submitForm } from "utils/form";
import { submitFeedback } from "api/sentry";
import useConfig from "hooks/use-config";

const ReportErrorContainer = props => {
  const config = useConfig();

  return (
    <ReportError
      onSubmit={(values, formikBag) => {
        const sentryDsn = config.SENTRY_DSN;
        const result = submitForm(submitFeedback)(
          { ...values, sentryDsn },
          formikBag
        );
        console.log(result);
      }}
      submitted={false}
      {...props}
    ></ReportError>
  );
};

export default ReportErrorContainer;
