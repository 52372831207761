import React, { useMemo } from "react";
import shortid from "shortid";
import { Field, connect, getIn } from "formik";
import classnames from "classnames";
import FormFieldError from "components/form-field-error";
import HelpButton from "components/button/help";
import FieldBadge from "components/field-badge";
import styles from "./form-field.module.css";

const FormField = ({
  name,
  label,
  inline = false,
  hideLabel = false,
  optional = false,
  badge,
  help = null,
  formik,
  isAdminField = false,
  readOnly,
  testId,
  ...restOfProps
}) => {
  const id = useMemo(() => name + shortid.generate(), [name]);
  const error = getIn(formik.errors, name);

  const hasHelp = optional || help || badge || isAdminField;

  const dynamicDataAttributes = {
    ...(readOnly && { "data-readonly": true }),
    ...(isAdminField && { "data-admin-field": true }),
    ...(error && { "data-has-error": true }),
    ...(testId && { "data-testid": testId })
  };

  return (
    <div
      className={classnames({
        [styles.container]: true,
        [`form-field-${restOfProps.type}`]: restOfProps.type,
        [styles.hasError]: error,
        [styles.admin]: isAdminField
      })}
      data-element="field"
      {...dynamicDataAttributes}
    >
      <div
        className={classnames({
          [styles.field]: true,
          [styles.inline]: inline
        })}
      >
        <div className={styles.extra}>
          {!hideLabel && (
            <label data-element="field-label" htmlFor={id}>
              {label}
            </label>
          )}
          {hasHelp && (
            <div className={styles.help}>
              {isAdminField && <FieldBadge>ADMIN</FieldBadge>}
              {optional && <FieldBadge>OPTIONAL</FieldBadge>}
              {badge && <FieldBadge>{badge}</FieldBadge>}
              {help && (
                <div className={styles.helpButton}>
                  <HelpButton>{help}</HelpButton>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.input}>
          <Field
            name={name}
            id={id}
            label={label}
            readOnly={readOnly}
            {...restOfProps}
          />
        </div>
      </div>
      <FormFieldError name={name} />
    </div>
  );
};

export default connect(FormField);
