import React, { useRef, useMemo, useState, useEffect, useContext } from "react";
import useGoogleMapsApi from "hooks/use-google-maps-api";
import BlockLoader from "components/block-loader";
import styles from "./map.module.css";

const MapContext = React.createContext();

export const Marker = ({ position, icon, draggable, onDragEnd }) => {
  const { mapInstance, google } = useContext(MapContext);
  const [markerInstance, setMarkerInstance] = useState(null);

  useEffect(() => {
    if (mapInstance && !markerInstance) {
      const marker = new google.maps.Marker({
        map: mapInstance,
        animation: null
      });
      if (onDragEnd) {
        marker.addListener("dragend", e => {
          onDragEnd({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        });
      }
      setMarkerInstance(marker);
    }
  }, [mapInstance, markerInstance, google, onDragEnd]);

  useEffect(() => {
    if (icon && markerInstance) {
      markerInstance.setIcon({
        url: icon,
        scaledSize: {
          width: 32,
          height: 32
        }
      });
    }
  }, [icon, markerInstance]);

  useEffect(() => {
    if (position && markerInstance) {
      markerInstance.setPosition(position);
    }
  }, [position, markerInstance]);

  useEffect(() => {
    if (markerInstance) {
      markerInstance.setDraggable(draggable);
    }
  }, [draggable, markerInstance]);

  return null;
};

const Map = ({
  aspectRatio = 30 / 17,
  loading = false,
  zoom = 12,
  center,
  mapTypeControl,
  streetViewControl,
  children
}) => {
  const { apiLoaded, google } = useGoogleMapsApi();
  const [tilesLoaded, setTilesLoaded] = useState(null);

  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);

  useEffect(() => {
    if (apiLoaded && !loading && !mapInstance) {
      const map = new google.maps.Map(mapRef.current, {
        mapTypeControl,
        streetViewControl
      });
      setMapInstance(map);
      google.maps.event.addListener(map, "tilesloaded", () => {
        setTilesLoaded(true);
      });
    }
  }, [
    apiLoaded,
    loading,
    google,
    mapRef,
    mapInstance,
    mapTypeControl,
    streetViewControl
  ]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setZoom(zoom);
    }
  }, [zoom, mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      mapInstance.setCenter(center);
    }
  }, [center, mapInstance]);

  const inlineStyles = useMemo(
    () => (aspectRatio ? { paddingBottom: `${(1 / aspectRatio) * 100}%` } : {}),
    [aspectRatio]
  );

  return (
    <div style={inlineStyles} className={styles.container}>
      <MapContext.Provider value={{ apiLoaded, google, mapInstance }}>
        <div
          style={{ opacity: tilesLoaded ? 1 : 0 }}
          className={styles.map}
          ref={mapRef}
        />
        {children}
      </MapContext.Provider>
      {(loading || !tilesLoaded) && <BlockLoader />}
    </div>
  );
};

Map.defaultProps = {
  center: { lat: 51.499617, lng: -0.140865 },
  zoom: 12,
  mapTypeControl: false,
  streetViewControl: false
};

export default Map;
