import React from "react";
import styles from "./field-badge.module.css";

const FieldBadge = ({ children }) => {
  return (
    <div data-element="field-badge" className={styles.container}>
      {children}
    </div>
  );
};

export default FieldBadge;
