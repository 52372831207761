import React, { useEffect, useState, useLayoutEffect } from "react";
import { Formik } from "formik";
import useNotifications from "components/notifications";
import FormDebug from "components/form-debug";
import Submit from "components/form-submit";
import PrimaryButton from "components/button/primary";

const Notifications = ({ formikBag }) => {
  const { status, isValid, isSubmitting } = formikBag;
  const { notifyInfo, removeNotificationsByTag } = useNotifications();

  useEffect(() => {
    if (status) {
      notifyInfo(status, { tag: "form" });
    }
  }, [status, notifyInfo]);

  useEffect(() => {
    if (isSubmitting && !isValid && !status) {
      notifyInfo("Some fields are not valid", {
        tag: "form",
        delay: 0.6
      });
    }
  }, [isValid, isSubmitting, status, notifyInfo]);

  useLayoutEffect(() => {
    if (isSubmitting) {
      removeNotificationsByTag("form");
    }
  }, [isSubmitting, removeNotificationsByTag]);

  return null;
};

const Form = ({
  debug = false,
  onSubmit,
  render,
  initialValues,
  validationSchema,
  submitButtonLabel,
  validateOnBlur = false,
  hideSubmit = false,
  stopPropagation = false,
  testId = "",
  children
}) => {
  const [validateOnChange, setValidateOnChange] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      isInitialValid={true}
      onSubmit={onSubmit}
      render={formikBag => {
        const { isSubmitting, isValid, handleSubmit } = formikBag;

        return (
          <form
            onSubmit={e => {
              if (stopPropagation) {
                e.stopPropagation();
              }
              handleSubmit(e);
            }}
            noValidate
            data-testid={testId}
          >
            {render(formikBag)}
            {!hideSubmit && (
              <Submit>
                <PrimaryButton
                  submitting={isSubmitting}
                  shake={isSubmitting && !isValid}
                  type="submit"
                  onClick={() => setValidateOnChange(true)}
                  data-testid="form-submit-button"
                >
                  {submitButtonLabel || "SUBMIT"}
                </PrimaryButton>
              </Submit>
            )}
            <Notifications formikBag={formikBag} />
            {debug && <FormDebug />}
            {children}
          </form>
        );
      }}
    />
  );
};

export default Form;
