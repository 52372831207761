export const addBreadcrumb = (tag = "general") => (
  message,
  { level = "info", category = tag, data = {} } = {}
) => {
  if (window.Sentry) {
    window.Sentry.addBreadcrumb({
      category,
      message,
      level,
      data
    });
  } else {
    // console.warn("Sentry log util: Sentry not loaded.");
  }
};

export const captureException = error => {
  if (window.Sentry) {
    window.Sentry.captureException(error);
  }
};
