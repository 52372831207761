import React, { useState, useEffect } from "react";
import flags from "react-phone-number-input/flags";
import styles from "./phone-field.module.css";

let PhoneComponent;

const PhoneField = ({ field, form, ...props }) => {
  const [isLoaded, setLoaded] = useState(null);

  useEffect(() => {
    import("react-phone-number-input").then((PhoneExport) => {
      PhoneComponent = PhoneExport.default;
      setLoaded(true);
    });
  }, []);

  return isLoaded ? (
    <PhoneComponent
      flags={flags}
      className={styles.container}
      displayInitialValueAsLocalNumber
      country={"GB"}
      name={field.name}
      value={field.value}
      onChange={(value) => {
        form.setFieldValue(field.name, value);
      }}
      onBlur={(e) => {
        const value = e.target.value || "";
        form.setFieldValue(field.name, value, false);
        form.setFieldTouched(field.name, true, true);
      }}
      {...props}
    />
  ) : (
    <input {...field} {...props} />
  );
};

export default PhoneField;
