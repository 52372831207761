import React from "react";
import classnames from "classnames";
import styles from "./form-container.module.css";

const FormContainer = ({ children, wide = false, ...props }) => (
  <div
    className={classnames({ [styles.wrapper]: true, [styles.wide]: wide })}
    {...props}
  >
    {children}
  </div>
);

export default FormContainer;
