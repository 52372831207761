import React, { useState, useRef, useLayoutEffect } from "react";
import Portal from "components/portal";
import posed from "react-pose";
import styles from "./dropdown.module.css";

const Container = posed.div({
  dropdownOpen: {
    opacity: 1,
    transition: { duration: 200 },
    staggerChildren: 30,
    delayChildren: 30,
    applyAtStart: { visibility: "visible" }
  },
  dropdownClosed: {
    opacity: 0,
    transition: { duration: 100 },
    applyAtEnd: { visibility: "hidden" }
  }
});

const Dropdown = ({ button, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const buttonRef = useRef(null);
  const containerRef = useRef(null);

  const setPosition = () => {
    const buttonBounds = buttonRef.current.getBoundingClientRect();
    const containerBounds = containerRef.current.getBoundingClientRect();

    setTop(Math.round(buttonBounds.top + buttonBounds.height + window.scrollY));
    setLeft(
      Math.max(
        0,
        Math.round(
          buttonBounds.left + buttonBounds.width - containerBounds.width
        )
      )
    );
  };

  const handleButtonClicked = () => {
    setPosition();
    setIsOpen(!isOpen);
  };

  useLayoutEffect(() => {
    const handleResize = () => setPosition();

    const handleOutsideClicked = e => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target) &&
        !buttonRef.current.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleOutsideClicked);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleOutsideClicked);
    };
  }, []);

  return (
    <>
      <div ref={buttonRef}>
        {React.cloneElement(button, { onClick: handleButtonClicked })}
      </div>
      <Portal>
        <Container
          initialPose="dropdownClosed"
          pose={isOpen ? "dropdownOpen" : "dropdownClosed"}
          key={"dropdown"}
          className={styles.container}
          style={{
            top,
            left
          }}
          ref={containerRef}
        >
          <div>{children}</div>
        </Container>
      </Portal>
    </>
  );
};

export default Dropdown;
