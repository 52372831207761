import React from "react";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import Field from "components/form-field";
import Fieldset from "components/form-fieldset";
import PhoneField from "components/phone-field";
import DateField from "components/date-field";
import SelectField from "components/select-field";
import Form from "components/form";

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  userProfile: Yup.object().shape({
    mobileNumber: Yup.string()
      .nullable()
      .required("Required: we may need to contact you about your event.")
      .test(
        "valid-phone-number",
        "Please enter a valid phone number: we may need to contact you about your event.",
        (value) => isValidPhoneNumber(value) || !value
      ),
  }),
  dateOfBirth: Yup.string().nullable(),
  sex: Yup.string().nullable(),
});

const defaultValues = {
  firstName: "",
  lastName: "",
  mobileNumber: "",
  userProfile: {
    mobileNumber: null,
    dateOfBirth: null,
    sex: null,
  },
};

const UserDetailsForm = ({
  initialValues = defaultValues,
  onSubmit,
  submitButtonLabel,
  autoFocus,
}) => {
  return (
    <Form
      testId="user-details-form"
      submitButtonLabel={submitButtonLabel}
      initialValues={initialValues}
      validationSchema={UserSchema}
      onSubmit={onSubmit}
      render={() => {
        return (
          <>
            <Fieldset>
              <Field
                autoFocus={autoFocus}
                name="firstName"
                label="FIRST NAME"
                type="text"
              />

              <Field name="lastName" label="LAST NAME" type="text"></Field>
              <Field name="email" label="EMAIL" type="email"></Field>
              <Field
                label="MOBILE NUMBER"
                name="userProfile.mobileNumber"
                component={PhoneField}
              />
              <Field
                optional
                label="DATE OF BIRTH"
                name="userProfile.dateOfBirth"
                component={DateField}
              />
              <Field
                optional
                label="GENDER"
                name="userProfile.sex"
                component={SelectField}
              >
                <option value=""></option>
                <option value="m">Male</option>
                <option value="f">Female</option>
                <option value="o">Not listed here</option>
              </Field>
            </Fieldset>
          </>
        );
      }}
    />
  );
};

export default UserDetailsForm;
