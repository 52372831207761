import React from "react";
import Modal from "../../";
import CloseIcon from "components/icons/close";
import IconButton from "components/button/icon";
import styles from "./slide-in.module.css";

const animationsDesktop = {
  initial: {
    opacity: 1,
    x: "100%",
    y: "0%"
  },
  enter: {
    opacity: 1,
    x: "0%",
    y: "0%",
    transition: { type: "spring", stiffness: 250, damping: 32 }
  },
  exit: {
    x: "100%",
    y: "0%",
    opacity: 1
  }
};

const animationsMobile = {
  initial: {
    opacity: 1,
    x: "0%",
    y: "100%"
  },
  enter: {
    opacity: 1,
    x: "0%",
    y: "0%",
    transition: { type: "spring", stiffness: 250, damping: 32 }
  },
  exit: {
    x: "0%",
    y: "100%",
    opacity: 1
  }
};

const SlideInDialogue = ({ children, onCloseClicked, ...props }) => {
  const mq = window.matchMedia("(min-width: 600px)");

  return (
    <Modal
      {...props}
      className={styles.portal}
      animations={mq.matches ? animationsDesktop : animationsMobile}
    >
      <div className={styles.slideIn}>
        <div className={styles.close}>
          <IconButton onClick={onCloseClicked}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.children}>{children}</div>
      </div>
    </Modal>
  );
};

export default SlideInDialogue;
