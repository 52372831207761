import React from "react";
import Button from "../";
import styles from "./text.module.css";

const TextButton = props => {
  return (
    <Button {...props} className={styles.text}>
      {props.children}
    </Button>
  );
};

export default TextButton;
