import { useEffect, useState } from "react";
import useConfig from "hooks/use-config";

const scriptId = "google-maps-api";

const useGoogleMapsApi = () => {
  const { GOOGLE_API_KEY_BROWSER } = useConfig();

  const [apiLoaded, setApiLoaded] = useState(false);
  const [google, setGoogle] = useState(null);

  const onLoad = e => {
    setGoogle(window.google);
    setApiLoaded(true);
  };

  useEffect(() => {
    const scriptEl = document.getElementById(scriptId);

    if (!window.google && scriptEl) {
      scriptEl.addEventListener("load", onLoad);
    } else if (!window.google || !window.google.places) {
      const script = document.createElement("script");
      script.id = scriptId;
      script.type = "text/javascript";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY_BROWSER}&libraries=places`;
      script.addEventListener(`load`, onLoad);
      document.body.appendChild(script);
    } else {
      onLoad();
    }
  }, [GOOGLE_API_KEY_BROWSER]);

  return { apiLoaded, google };
};

export default useGoogleMapsApi;
