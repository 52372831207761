import React, { useEffect, useState } from "react";
import ReportError from "containers/report-error";
import useNotifications from "components/notifications";

const Error = ({ eventId }) => {
  const [reportModalOpen, setReportModalOpen] = useState(false);

  const { notifyError } = useNotifications();

  useEffect(() => {
    notifyError(
      <>
        Something went wrong. If you'd like to help,
        <button onClick={() => setReportModalOpen(true)}>
          tell us what happened
        </button>
      </>
    );
  }, [notifyError]);

  return (
    <div
      style={{
        width: "280px",
        textAlign: "center",
        margin: "0 auto",
        padding: "16px 0"
      }}
    >
      <h1
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          padding: "0",
          lineHeight: 1.5
        }}
      >
        <span aria-label="Error" role="img" style={{ fontSize: "32px" }}>
          🤦
        </span>
        <br /> Something went wrong.
      </h1>
      <p style={{ fontSize: "14px", padding: "4px 0 16px 0" }}>
        Our team has been notified. If you'd like to help,{" "}
        <a
          role="button"
          onClick={() => {
            // setReportModalOpen(true);
            window.Sentry.showReportDialog({ eventId });
          }}
        >
          tell us what happened
        </a>
      </p>
      <ReportError
        isOpen={reportModalOpen}
        onBackdropClicked={() => setReportModalOpen(false)}
        onSubmitted={() => setReportModalOpen(false)}
        eventId={eventId}
      />
    </div>
  );
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error, info });

    if (window.Sentry) {
      window.Sentry.addBreadcrumb({
        category: "react",
        message: "React error boundary"
      });
      window.Sentry.withScope(scope => {
        scope.setExtra("info", info);
        const eventId = window.Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error error={this.state.error} eventId={this.state.eventId}></Error>
      );
    }
    return this.props.children;
  }
}
