import React from "react";
import { getAccount, getAccounts } from "api/accounts";
import EntityAutoCompleteField from "components/entity-auto-complete-field";
import { DropdownItem } from "components/input-dropdown";

const SalesAccountField = ({ placeholder, form, field, id }) => {
  return (
    <EntityAutoCompleteField
      id={id}
      field={field}
      form={form}
      placeholder={placeholder}
      fetchEntity={getAccount}
      fetchData={getAccounts}
      minCharacters={0}
      renderItem={item => {
        return (
          <DropdownItem>
            <img title={item.text} alt={item.id} src={item.data.logo} />
            {item.text}
          </DropdownItem>
        );
      }}
    ></EntityAutoCompleteField>
  );
};

export default SalesAccountField;
