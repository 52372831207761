import React from "react";
import FormContainer from "components/form-container";
import RegisterForm from "components/forms/register";
import FormPage from "components/form-page";

const Register = ({
  onSubmit,
  captchaSiteKey,
  heading = "Before setting up an account, you'll need to quickly register yourself."
}) => {
  return (
    <FormPage
      header={
        <>
          <h2>{heading}</h2>
        </>
      }
    >
      <FormContainer>
        <RegisterForm captchaSiteKey={captchaSiteKey} onSubmit={onSubmit} />
      </FormContainer>
    </FormPage>
  );
};

export default Register;
