import React from "react";
import { navigate } from "@reach/router";
import { register, setAccount } from "api/users";
import { createAccount } from "api/accounts";
import { createEvent } from "api/events";
import { submitForm } from "utils/form";
import Onboarding from "components/onboarding";
import tagManager from "utils/tag-manager";

const steps = ["Registration", "Sales Account", "Create Event"];

const OnboardingContainer = ({ captchaSiteKey, step }) => {
  return (
    <>
      <Onboarding
        captchaSiteKey={captchaSiteKey}
        steps={steps}
        currentStep={step}
        onSubmitRegister={async (values, formikBag) => {
          const captchaToken = window.grecaptcha
            ? await window.grecaptcha.execute()
            : "";

          const result = await submitForm(register)(
            { ...values, captchaToken },
            formikBag
          );

          if (result) {
            // The GTM dataLayer events are currently being handled in the API, and rendered onto the base template
            // see `google_tag_manager_head.html`
            // tagManager.push({ event: tagManager.events.userCreated });
            window.location.href = "/";
          }
        }}
        onSubmitSalesAccount={async (values, formikBag) => {
          const result = await submitForm(createAccount)(values, formikBag);

          if (result) {
            // The GTM dataLayer event is handled client-side as there is no redirect following it.
            tagManager.push({
              event: tagManager.events.salesAccountCreated,
              fixr_salesaccount_id: result.id
            });
            const account = await submitForm(setAccount)(
              { id: result.id },
              formikBag
            );

            if (account) {
              navigate("/welcome/create-event");
            }
          }
        }}
        onSubmitEvent={async (values, formikBag) => {
          const result = await submitForm(createEvent)(values, formikBag);

          if (result) {
            // The GTM dataLayer events are currently being handled in the API, and rendered onto the base template
            // see `google_tag_manager_head.html`
            // tagManager.push({ event: tagManager.events.eventCreated });
            window.location.href = `/events/${result.id}`;
          }
        }}
      />
    </>
  );
};

export default OnboardingContainer;
