import { get } from "./";
import endpoints from "../constants/endpoints";

export async function getBookingFeeScales(searchTerm) {
  return get(`${endpoints.bookingFeeScales}?search=${searchTerm || ""}`);
}

export async function getBookingFeeScale(id) {
  return get(`${endpoints.bookingFeeScales}/${id}`);
}
