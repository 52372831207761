import "./polyfills.js";
import React from "react";
import ReactDOM from "react-dom";
import "./global.css";
import Events from "./containers/events";
import CopyToClipboardButton from "./components/button/copy-to-clipboard";
import CopyToClipboard from "./components/copy-to-clipboard";
import CreateSalesAccount from "./containers/create-sales-account";
import Onboarding from "./containers/onboarding";
import EditEvent from "./containers/edit-event";
import EditSalesAccount from "./containers/edit-sales-account";
import HeaderNavigation from "components/header-navigation";
import LoaderDots from "components/loader-dots";
import Login from "containers/login";
import Register from "containers/register";
import PrimaryButton from "components/button/primary";
import SecondaryButton from "components/button/secondary";
import ResetPassword from "containers/reset-password";
import Notify from "components/notify";
import ErrorBoundary from "components/error-boundary";
import AppProviders from "components/app-providers";
import { getComponentsInPage } from "utils/dom";
import EditUserDetails from "containers/edit-user-details";
import RoutesRoot from "components/routes-root";

// import registerServiceWorker from './registerServiceWorker';

const components = {
  events: Events,
  "copy-to-clipboard-button": CopyToClipboardButton,
  "copy-to-clipboard": CopyToClipboard,
  "create-sales-account": CreateSalesAccount,
  "header-navigation": HeaderNavigation,
  "loader-dots": LoaderDots,
  "primary-button": PrimaryButton,
  "secondary-button": SecondaryButton,
  login: Login,
  "reset-password": ResetPassword,
  onboarding: Onboarding,
  register: Register,
  notify: Notify,
  "edit-event": EditEvent,
  "edit-sales-account": EditSalesAccount,
  "edit-user-details": EditUserDetails,
  "routes-root": RoutesRoot,
};

const App = ({ components }) => {
  return (
    <AppProviders>
      {components.map(({ el, component, props }) =>
        ReactDOM.createPortal(
          <ErrorBoundary>
            {React.createElement(component, props)}
          </ErrorBoundary>,
          el
        )
      )}
    </AppProviders>
  );
};

// create common root component so we can share context between components
const root = document.createElement("div");
root.id = "react-root";
document.body.appendChild(root);

// gather components on the current page
const componentsToRender = getComponentsInPage(components);

ReactDOM.render(
  <App components={componentsToRender} />,
  document.getElementById("react-root")
);
