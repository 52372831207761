import React from "react";
import TickIcon from "components/icons/tick";
import styles from "./checkbox.module.css";

const Checkbox = ({ id, label, ...checkboxProps }) => {
  return (
    <div className={styles.container}>
      <input id={id} type="checkbox" {...checkboxProps} />
      <div className={styles.box}>
        <TickIcon />
      </div>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
