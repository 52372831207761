import React from "react";
import FormContainer from "components/form-container";
import LoginForm from "components/forms/login";
import FacebookButton from "components/button/facebook";
import SecondaryButton from "components/button/secondary";
import LinkButton from "components/button/link";
import FormSeparator from "components/form-separator";
import FormPage from "components/form-page";
import styles from "./login.module.css";

const Login = ({ onSubmit, nextParam = "/", heading = "" }) => {
  return (
    <FormPage
      header={
        <>
          <h2>{heading}</h2>
        </>
      }
    >
      <FormContainer>
        <LoginForm onSubmit={onSubmit} />
        <div className={styles.forgotPassword}>
          <a href="/user/reset-password">Forgot your password?</a>
        </div>
        <FormSeparator />

        <LinkButton
          icon={null}
          buttonElement={FacebookButton}
          href={`/api/v2/app/login/facebook/?next=${nextParam}`}
        >
          SIGN IN WITH FACEBOOK
        </LinkButton>

        <FormSeparator />
        <p className={styles.register}>Don't have an account?</p>
        <LinkButton
          buttonElement={SecondaryButton}
          href={`/user/register?next=${nextParam}`}
        >
          CREATE ACCOUNT
        </LinkButton>
      </FormContainer>
    </FormPage>
  );
};

export default Login;
