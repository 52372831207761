import React from "react";
import classnames from "classnames";

const Circle = ({ size = 4, className = "", circleClassName = "" }) => (
  <svg
    className={classnames({ [className]: className })}
    viewBox={`0 0 ${size * 2} ${size * 2}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className={classnames({ [circleClassName]: circleClassName })}
      cx={size}
      cy={size}
      r={size}
    />
  </svg>
);

export default Circle;
