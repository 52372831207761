import React from "react";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import Field from "components/form-field";
import PhoneField from "components/phone-field";
import ImageField from "components/image-field";
import SelectField from "components/select-field";
import SlugField from "components/slug-field";
import HubsField from "components/hubs-field";
import BookingFeeScalesField from "components/booking-fee-scales-field";
import Fieldset from "components/form-fieldset";
import Form from "components/form";
import Auth, { useAuth } from "components/auth";

const SalesAccountSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  mobileNumber: Yup.string()
    .required("Required")
    .test(
      "valid-phone-number",
      "Not a valid phone number",
      (value) => isValidPhoneNumber(value) || !value
    ),
});

const defaultValues = {
  name: "",
  mobileNumber: "",
  description: "",
  logoId: null,
  defaultCurrency: "GBP",
  website: "",
  twitterUrl: "",
  urlSlug: "",
  googleAnalyticsTrackingId: "",
  facebookTrackingPixelId: "",
  hubs: [],
};

const SalesAccountForm = ({
  initialValues = defaultValues,
  onSubmit,
  submitButtonLabel,
}) => {
  const { hasPermission } = useAuth();

  return (
    <Form
      testId="sales-account-form"
      submitButtonLabel={submitButtonLabel}
      initialValues={initialValues}
      validationSchema={SalesAccountSchema}
      onSubmit={onSubmit}
      render={() => (
        <>
          <Fieldset>
            <Auth permissions={"fixrcms.staff"}>
              <Field
                name="bookingFeeScale"
                placeholder="Search"
                component={BookingFeeScalesField}
                label="BOOKING FEE SCALE"
                isAdminField
                validate={(value) => {
                  if (!value) {
                    return "Required.";
                  }
                }}
              />
            </Auth>
            <Field
              name="name"
              autoComplete="new-organiser"
              type="text"
              label="ORGANISER NAME"
              readOnly={!hasPermission("fixrcms.staff")}
              isAdminField={hasPermission("fixrcms.staff")}
              help={
                !hasPermission("fixrcms.staff") &&
                "Contact FIXR to change your organiser name."
              }
            />

            <Field
              name="description"
              component="textarea"
              label="DESCRIPTION"
              optional
            />
            <Field
              name="logoId"
              label="LOGO"
              aspectRatio={1 / 1}
              minWidth={100}
              imageType="Venue_Logo"
              component={ImageField}
              optional
            />
            <Field
              name="mobileNumber"
              type="tel"
              label="MOBILE NUMBER"
              autoComplete="tel"
              component={PhoneField}
            />

            <Field
              name="defaultCurrency"
              label="DEFAULT CURRENCY"
              component={SelectField}
            >
              <option value="GBP">GBP</option>
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
            </Field>
          </Fieldset>

          <Fieldset legend="Promotion" isCollapsible isInitiallyCollapsed>
            <Field
              name="urlSlug"
              readOnly={!hasPermission("fixrcms.staff") && initialValues.urlSlug}
              isAdminField={
                hasPermission("fixrcms.staff") && initialValues.urlSlug
              }
              placeholder="mynamehere"
              optional={!initialValues.urlSlug}
              autoComplete="new-slug"
              type="text"
              label="PAGE ON FIXR"
              help={
                <>
                  Your FIXR web address will list all of you public events in
                  one place. You can choose your own name, your company name or
                  anything else.
                  {initialValues.urlSlug && (
                    <>
                      <br />
                      <br />
                      Please contact FIXR if you want to change your address.
                    </>
                  )}
                </>
              }
              validate={(value) => {
                if (!value && initialValues.urlSlug) {
                  return "Required.";
                }
              }}
              component={SlugField}
            />
            <Field
              name="website"
              type="url"
              label="WEBSITE"
              placeholder="https://example.com"
              optional
            />
            <Field
              name="facebookUrl"
              type="url"
              label="FACEBOOK PAGE"
              placeholder="https://facebook.com/mypage"
              optional
            />
            <Field
              name="twitterUrl"
              type="url"
              label="TWITTER ACCOUNT"
              placeholder="https://twitter.com/myusername"
              optional
            />
          </Fieldset>

          <Fieldset legend="Preferred audience" badge="OPTIONAL">
            <Field
              name="hubs"
              help="This helps promote your event in specific places."
              placeholder="Search"
              component={HubsField}
              label="CITIES AND UNIVERSITIES"
            />
          </Fieldset>
          <Fieldset
            legend="Analytics"
            isCollapsible
            isInitiallyCollapsed={true}
          >
            <Field
              name="facebookTrackingPixelId"
              type="text"
              label="FACEBOOK PIXEL ID"
              optional
            />
            <Field
              name="googleAnalyticsTrackingId"
              type="text"
              label="GOOGLE ANALYTICS TRACKING ID"
              optional
            />
          </Fieldset>
        </>
      )}
    />
  );
};

export default SalesAccountForm;
