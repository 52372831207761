import { useState, useEffect } from "react";
import { addBreadcrumb, captureException } from "utils/sentry";

const useNextParam = () => {
  const [nextParam, setNextParam] = useState("/");

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      setNextParam(urlParams.get("next") || "/");
    } catch (e) {
      addBreadcrumb(
        "Could not parse URL params. Polyfill needed for URLSearchParams?"
      );
      captureException(e);
    }
  }, []);

  return nextParam;
};

export default useNextParam;
