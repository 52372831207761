import React, { useEffect, useRef } from "react";
import { Field } from "formik";
import FormFieldError from "components/form-field-error";
import styles from "./captcha-field.module.css";

const Captcha = ({ form, field, siteKey }) => {
  const ref = useRef(null);

  useEffect(() => {
    window.captchaOnload = async () => {
      window.grecaptcha.render(ref.current, {
        sitekey: siteKey,
        badge: "inline",
        size: "invisible"
      });
      document.querySelector(".grecaptcha-logo iframe").tabIndex = -1;
    };

    if (siteKey && !document.getElementById("captcha-v3")) {
      const script = document.createElement("script");
      script.id = "captcha-v3";
      script.src = `https://www.google.com/recaptcha/api.js?onload=captchaOnload`;
      document.body.appendChild(script);
    }
  }, [ref, siteKey]);

  return (
    <div data-element="field">
      <div className={styles.captcha} ref={ref} />
      <FormFieldError name={field.name} />
    </div>
  );
};

const CaptchaField = ({ name, ...props }) => {
  return <Field name={name} component={Captcha} {...props} />;
};

export default CaptchaField;
