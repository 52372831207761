import React from "react";
import styles from "./dropdown-menu.module.css";
import posed from "react-pose";

const Item = posed.div({
  enter: {
    opacity: 1,
    y: "0",
    transition: { type: "spring", stiffness: 350, damping: 28 }
  },
  exit: { opacity: 0 },
  preEnter: {
    opacity: 0,
    y: "-10px"
  }
});

const DropdownMenu = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item, i) => {
        return (
          <Item className={styles.item} key={i}>
            {item}
          </Item>
        );
      })}
    </div>
  );
};

export default DropdownMenu;
