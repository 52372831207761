import React from "react";

const TickIcon = () => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.071581.43265l-8.992 8.88-2.45-2.801c-.545-.623-1.493-.687-2.117-.141-.624.546-.687 1.493-.141 2.117l3.5 4c.272.311.661.496 1.074.511.019.001.037.001.055.001.394 0 .773-.155 1.054-.432l10.125-10c.589-.583.595-1.533.013-2.122s-1.532-.595-2.121-.013"
      fill="#CC003E"
      fillRule="evenodd"
    />
  </svg>
);

export default TickIcon;
