import React from "react";
import "./styles.css";

const RadioButton = ({
  value,
  disabled,
  checked,
  name,
  id,
  children,
  onChange,
}) => {
  return (
    <div className="radio-button">
      <input
        disabled={disabled}
        value={value}
        onChange={() => onChange(value)}
        type="radio"
        checked={checked}
        id={id}
        name={name}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export default RadioButton;
