import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import EventListItem from './components/event-list-item';
import './styles.css';

const PosedEvent = posed.div({
  enter: {
    opacity: 1,
    y: '0%',
    delay: ({ i, total }) => i * 75 - (total - Math.min(10, total)) * 75
  },
  exit: { opacity: 0, y: '0%' },
  preEnter: { opacity: 0, y: '10%' }
});

const EventList = ({ events = [] }) => {
  return (
    <div className="event-list">
      <PoseGroup animateOnMount={true} preEnterPose="preEnter">
        {events.map((event, i) => (
          <PosedEvent key={i} i={i} total={events.length}>
            <EventListItem event={event} />
          </PosedEvent>
        ))}
      </PoseGroup>
    </div>
  );
};

export default EventList;
