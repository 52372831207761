import React from "react";
import PropTypes from "prop-types";
import FormFieldReset from "components/form-field-reset";
import BlockLoader, { Line } from "components/block-loader";
import Map, { Marker } from "components/map";
import styles from "./place.module.css";

const Place = ({
  position,
  details,
  loading = false,
  image,
  onResetClicked,
  onDragEnd,
  draggable = false,
  hideAddress = false,
  showMap = true,
  aspectRatio,
  zoom,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.map}>
        {showMap && (
          <Map
            zoom={zoom}
            aspectRatio={aspectRatio}
            loading={loading}
            center={position}
          >
            {position && (
              <Marker
                position={position}
                icon={image}
                onDragEnd={onDragEnd}
                draggable={draggable}
              />
            )}
          </Map>
        )}
        <FormFieldReset onClick={onResetClicked}>Reset</FormFieldReset>
      </div>
      {!hideAddress && (
        <div className={styles.details}>
          {loading ? (
            <>
              <BlockLoader />
              <Line style={{ width: "70%" }} />
              <Line style={{ width: "70%" }} />
              <Line style={{ width: "70%" }} />
            </>
          ) : (
            details && (
              <>
                <div>
                  <b>{details.name}</b>
                </div>
                <div>{details.address}</div>
                <div>
                  {details.city} {details.postcode}
                </div>
              </>
            )
          )}
        </div>
      )}
    </div>
  );
};

Place.propTypes = {
  details: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postcode: PropTypes.string,
  }),
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default Place;
