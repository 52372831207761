import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

const Tab = ({ children, onClick, isActive }) => {
	return (
		<div
			onClick={onClick}
			className={classnames({ Tab: true, 'Tab--active': isActive })}
		>
			{children}
		</div>
	);
};

Tab.propTypes = {
	onClick: PropTypes.func,
	isActive: PropTypes.bool
};

Tab.defaultProps = {
	onClick: () => {}
};

export default Tab;
