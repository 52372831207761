import React from "react";
import classnames from "classnames";
import styles from "./multi-select-auto-complete.module.css";
import IconButton from "components/button/icon";
import CrossIcon from "components/icons/cross";
import AutoComplete from "components/auto-complete";

const MultiSelectAutoComplete = ({
  id,
  value,
  onChange,
  onSelect,
  placeholder,
  suggestions = [],
  selectedItems = [],
  maxItems = 0,
  onRemove,
  loading,
  multiSelect = false,
  minCharacters = 1,
  renderItem
}) => {
  const itemCount = selectedItems.length;

  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.multiSelect]: multiSelect
      })}
    >
      {selectedItems.length > 0 && (
        <div className={styles.selectedContainer}>
          {selectedItems.map((item, i) => (
            <div key={i} className={styles.selected}>
              <span>{item.text}</span>
              <IconButton
                onClick={() => {
                  onRemove(item);
                }}
              >
                <CrossIcon />
              </IconButton>
            </div>
          ))}
        </div>
      )}
      {(!maxItems || itemCount < maxItems) && (
        <div style={{ marginTop: "12px" }}>
          <AutoComplete
            id={id}
            loading={loading}
            value={value}
            suggestions={suggestions}
            minCharacters={minCharacters}
            placeholder={placeholder}
            onChange={onChange}
            onSelect={onSelect}
            renderItem={renderItem}
          />
        </div>
      )}
    </div>
  );
};

export default MultiSelectAutoComplete;
