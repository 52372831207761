const { DateTime } = require('luxon');
// { DATETIME_FULL, DATE_SHORT, TIME_SIMPLE } = DateTime}
export const dateFormats = {
	EVENT_TIME_HOURS: {
		hour: '2-digit',
		minute: '2-digit',
		timeZoneName: 'short',
		hour12: true
	},
	EVENT_TIME_FULL: {
		weekday: 'long',
		day: 'numeric',
		month: 'long',
		year: 'numeric'
	}
};

const DateOutput = ({
	date,
	format = DateTime.DATE_SHORT,
	timeZone,
	locale = 'en-GB'
}) => DateTime.fromISO(date, { zone: timeZone, locale }).toLocaleString(format);

export default DateOutput;
