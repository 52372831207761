import React from "react";
import SlideInModal from "components/modal/variants/slide-in";
import ReportErrorForm from "components/forms/report-error";
import styles from "./report-error.module.css";

const ReportError = ({ onSubmit, isOpen, onOutsideClicked }) => {
  return (
    <div className={styles.container}>
      <SlideInModal isOpen={isOpen} onOutsideClicked={onOutsideClicked}>
        <ReportErrorForm onSubmit={onSubmit}></ReportErrorForm>
      </SlideInModal>
    </div>
  );
};

export default ReportError;
