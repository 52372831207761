import React from "react";
import { NotificationsProvider } from "components/notifications";
import { ConfigProvider } from "context/config";

const AppProviders = ({ children }) => {
  return (
    <ConfigProvider>
      <NotificationsProvider>{children}</NotificationsProvider>
    </ConfigProvider>
  );
};

export default AppProviders;
