import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

export default class Portal extends React.PureComponent {
  static propTypes = {
    elementType: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      attachedToDom: false
    };

    this.el = document.createElement(props.elementType || "div");
    if (props.className) {
      this.el.className = props.className;
    }
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    this.setState({ attachedToDom: true });
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    return this.state.attachedToDom
      ? ReactDOM.createPortal(this.props.children, this.el)
      : null;
  }
}
