import { get, post, patch } from "./";
import endpoints from "../constants/endpoints";

const endpoint = endpoints.events;

export async function getEvents(limit, offset, mode) {
  return get(`${endpoint}?limit=${limit}&offset=${offset}&mode=${mode}`);
}

export async function getEvent(id) {
  return get(`${endpoint}/${id}`);
}

export async function updateEvent({ id, ...data }) {
  return patch(`${endpoint}/${id}`, data);
}

export async function createEvent(data) {
  return post(`${endpoint}`, data);
}
