import React from "react";
import * as Yup from "yup";
import Field from "components/form-field";
import Form from "components/form";
import Fieldset from "components/form-fieldset";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const defaultValues = {
  email: "",
  password: "",
};

const LoginForm = ({ initialValues = defaultValues, onSubmit }) => {
  return (
    <Form
      testId="login-form"
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
      submitButtonLabel="SIGN IN"
      render={() => {
        return (
          <Fieldset>
            <Field
              autoFocus
              name="email"
              type="text"
              label="EMAIL"
              autoComplete="username"
            />
            <Field
              name="password"
              type="password"
              label="PASSWORD"
              autoComplete="password"
            />
          </Fieldset>
        );
      }}
    />
  );
};

export default LoginForm;
