import React, { useState } from "react";
import classnames from "classnames";
import posed from "react-pose";
import Loader from "components/loader-dots";
import Button from "components/button";
import styles from "./submit.module.css";

const ContainerPose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -2 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 600,
      damping: 10
    }
  }
});

const Background = posed.span({
  init: {
    scaleX: 1
  },
  hover: {
    scaleX: 1
  },
  submitting: {
    scaleX: 1
  }
});

const Icon = posed.span({
  init: {
    opacity: 0,
    y: "-50%",
    x: 0,
    transition: {
      duration: 100
    }
  },
  hover: {
    opacity: 1,
    y: "-50%",
    x: "-28px"
  },
  submitting: {
    opacity: 0
  }
});

const SubmitButton = ({
  children,
  className = "",
  submitting = false,
  disabled = false,
  shake = false,
  size = "medium",
  icon = null,
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const poses = [
    "init",
    ...(isHovering && !submitting ? ["hover"] : []),
    ...(submitting ? ["submitting"] : []),
    ...(shake ? ["shake"] : [])
  ];

  return (
    <ContainerPose
      className={styles.container}
      pose={poses}
      data-element="button-container"
      data-size={size}
    >
      <Button
        data-element="button"
        disabled={submitting || disabled}
        data-submitting={submitting}
        className={classnames({
          [styles.submit]: true,
          [className]: className
        })}
        {...props}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onFocus={() => setIsHovering(true)}
        onBlur={() => setIsHovering(false)}
      >
        <Background
          pose={submitting ? "init" : ""}
          className={styles.background}
          data-element="background"
        />
        {submitting && (
          <span className={styles.loader}>
            <Loader data-element="loader" />
          </span>
        )}
        <span className={styles.children} data-element="children">
          {children}
        </span>
        {icon && (
          <Icon className={styles.icon} data-element="icon">
            {icon}
          </Icon>
        )}
      </Button>
    </ContainerPose>
  );
};

export default SubmitButton;
