import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Onboarding from "containers/onboarding";
import ScrollToTop from "components/scroll-to-top";

const RedirectToRoot = () => {
  useEffect(() => {
    window.location.replace("/");
  }, []);

  return null;
};

const RoutesRoot = () => {
  return (
    <Router primary={false}>
      <ScrollToTop path="/">
        <Onboarding step={1} path="welcome/create-account"></Onboarding>
        <Onboarding step={2} path="welcome/create-event"></Onboarding>
        <RedirectToRoot path="/welcome/*"></RedirectToRoot>
      </ScrollToTop>
    </Router>
  );
};

export default RoutesRoot;
