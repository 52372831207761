const endpoints = {
  events: `/api/v2/organiser/events`,
  accounts: `/api/v2/organiser/accounts`,
  ticketShop: `/api/v2/organiser/ticket-shop`,
  images: `/api/v2/organiser/images`,
  venues: `/api/v2/organiser/venues`,
  entryConditions: `/api/v2/organiser/entryconditions`,
  user: `/api/v2/organiser/user`,
  userLegacy: "/api/v2/app/user",
  users: `/api/v2/organiser/users`,
  tags: `/api/v2/organiser/tags`,
  hubs: `/api/v2/organiser/hubs`,
  bookingFeeScales: `/api/v2/organiser/bookingfeescales`,
  sentryUserFeedback: `https://sentry.io/api/0/projects/fixr/server-api-cms-js/user-feedback/`
};

export default endpoints;
