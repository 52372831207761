import React, { useRef } from "react";
import FieldBadge from "components/field-badge";
import styles from "./form-fieldset.module.css";

const FormFieldset = ({
  legend,
  isCollapsible = false,
  isInitiallyCollapsed = false,
  badge = "",
  children,
  ...props
}) => {
  const toggleRef = useRef(null);

  return (
    <fieldset {...props} className={styles.fieldset}>
      {legend && (
        <div ref={toggleRef} className={styles.legend}>
          {legend}
          {badge && <FieldBadge>{badge}</FieldBadge>}
        </div>
      )}
      <div className={styles.children}>{children}</div>
    </fieldset>
  );
};

export default FormFieldset;
