import { get, post, postAsFormData } from "./";
import endpoints from "../constants/endpoints";

const endpoint = endpoints.images;

export async function createImage(data) {
  return postAsFormData(endpoint, data);
}

export async function getImage(id) {
  return get(`${endpoint}/${id}`);
}

export async function cropImage(id, data) {
  return post(`${endpoint}/${id}/crop`, data);
}
