import { get, post } from "./";
import endpoints from "../constants/endpoints";

const endpoint = endpoints.venues;

export async function getVenues(searchTerm, online) {
  return get(`${endpoint}?search=${searchTerm}&online=${online}`);
}

export async function getVenue(id) {
  return get(`${endpoint}/${id}`);
}

export async function createVenue(data) {
  return post(endpoint, data);
}
