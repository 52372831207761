import React, {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import Button from "components/button";
import shortid from "shortid";
import styles from "./notifications.module.css";

const Context = React.createContext();

const icons = {
  info: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M10 6.036c.431 0 .78.345.78.776v.007a.779.779 0 1 1-1.56 0c0-.43.349-.783.78-.783zm0 2.78c.431 0 .78.35.78.78v4.112a.779.779 0 1 1-1.56 0V9.595c0-.43.349-.779.78-.779zm0 9.626c-4.655 0-8.442-3.787-8.442-8.442S5.345 1.558 10 1.558 18.442 5.345 18.442 10 14.655 18.442 10 18.442zM10 0C4.483 0 0 4.486 0 10s4.483 10 10 10c5.512 0 10-4.486 10-10S15.512 0 10 0z"
      />
    </svg>
  ),
  error: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M10 13.964a.775.775 0 0 1-.78-.776v-.007a.779.779 0 1 1 1.56 0c0 .43-.349.783-.78.783zm0-2.78a.779.779 0 0 1-.78-.78V6.293a.779.779 0 1 1 1.56 0v4.113c0 .43-.349.779-.78.779zm0-9.626c4.655 0 8.442 3.787 8.442 8.442S14.655 18.442 10 18.442 1.558 14.655 1.558 10 5.345 1.558 10 1.558zM10 20c5.517 0 10-4.486 10-10S15.517 0 10 0C4.488 0 0 4.486 0 10s4.488 10 10 10z"
      />
    </svg>
  ),
  success: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M13.073 7.108l-4.671 4.613-1.273-1.455a.78.78 0 0 0-1.173 1.026l1.818 2.078a.779.779 0 0 0 .558.266h.029a.78.78 0 0 0 .547-.224l5.26-5.195a.78.78 0 0 0-1.095-1.109M10 18.442c-4.655 0-8.442-3.787-8.442-8.442S5.345 1.558 10 1.558 18.442 5.345 18.442 10 14.655 18.442 10 18.442zM10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0z"
      />
    </svg>
  )
};

const Notification = ({
  children,
  id,
  category = "info",
  timeout = 0,
  delay = 0,
  onDismiss
}) => {
  const timer = useRef(null);
  const timerTimeout = (timeout + delay) * 1000;
  const [isAnimating, setAnimating] = useState(timeout > 0);

  const timerVariants = {
    on: targetDuration => ({
      x: "0%",
      opacity: 1,
      transition: { duration: targetDuration, delay, ease: "linear" }
    }),
    off: { x: "calc(-100% + 8px)", transition: { duration: 0.35 } }
  };

  useEffect(() => {
    if (timeout > 0) {
      timer.current = setTimeout(() => onDismiss(id), timerTimeout);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [onDismiss, timeout, id, delay, timer, timerTimeout]);

  return (
    <div className={styles.notification} data-category={category}>
      <div
        className={styles.inner}
        onClick={() => onDismiss(id)}
        onMouseEnter={() => {
          if (timer.current) {
            clearTimeout(timer.current);
            setAnimating(false);
          }
        }}
        onMouseLeave={() => {
          if (timer.current) {
            timer.current = setTimeout(() => onDismiss(id), timerTimeout);
            setAnimating(true);
          }
        }}
      >
        <motion.div
          className={styles.timer}
          initial="off"
          animate={isAnimating ? "on" : "off"}
          variants={timerVariants}
          custom={timeout}
        ></motion.div>
        <div className={styles.icon}>{icons[category]}</div>
        <div className={styles.children}>{children}</div>
        <div className={styles.dismiss}>
          <motion.div
            initial={{ y: "0%", opacity: 0 }}
            animate={{
              y: "0%",
              opacity: 1,
              transition: { delay: 0.4 }
            }}
          >
            <Button onClick={() => onDismiss(id)} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#FFF"
                  fillRule="evenodd"
                  d="M9.228 8l6.517-6.517A.868.868 0 1 0 14.517.255L8 6.77 1.483.255A.868.868 0 1 0 .255 1.483L6.77 8 .255 14.517a.868.868 0 1 0 1.228 1.228L8 9.228l6.517 6.517a.866.866 0 0 0 1.228 0 .868.868 0 0 0 0-1.228L9.228 8"
                />
              </svg>
            </Button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

// Provider
export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  const removeNotificationById = useCallback(id => {
    setNotifications(n => n.filter(n => n.id !== id));
  }, []);

  const removeNotificationsByTag = useCallback(tag => {
    setNotifications(notifications => [
      ...notifications.filter(n => n.tag !== tag)
    ]);
  }, []);

  const addNotification = useCallback(
    (content, { timeout = 0, category = "info", tag = "", delay = 0 } = {}) => {
      const id = shortid.generate();
      const notification = { content, id, timeout, category, tag, delay };
      setNotifications(notifications => [...notifications, notification]);
    },
    []
  );

  const notifyError = useCallback(
    (content, options = {}) =>
      addNotification(content, { category: "error", timeout: 0, ...options }),
    [addNotification]
  );

  const notifySuccess = useCallback(
    (content, options = {}) =>
      addNotification(content, { category: "success", timeout: 3, ...options }),
    [addNotification]
  );

  const notifyInfo = useCallback(
    (content, options = {}) =>
      addNotification(content, { category: "info", timeout: 0, ...options }),
    [addNotification]
  );

  return (
    <Context.Provider
      value={{
        notify: addNotification,
        notifyError,
        notifySuccess,
        notifyInfo,
        removeNotificationById,
        removeNotificationsByTag,
        removeAllNotifications: () => setNotifications([])
      }}
    >
      {children}
      <div className={styles.container}>
        <AnimatePresence>
          {notifications.map(notification => (
            <motion.div
              positionTransition={{
                type: "spring",
                stiffness: 200,
                damping: 30
              }}
              key={notification.id}
              initial={{ opacity: 0, x: "100%" }}
              animate={{
                opacity: 1,
                x: "0%",
                transition: {
                  type: "spring",
                  stiffness: 200,
                  damping: 30,
                  delay: notification.delay
                }
              }}
              exit={{ opacity: 0, x: "100%", transition: { duration: 0.25 } }}
            >
              <Notification
                id={notification.id}
                category={notification.category}
                onDismiss={removeNotificationById}
                timeout={notification.timeout}
                delay={notification.delay}
              >
                {notification.content}
              </Notification>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </Context.Provider>
  );
}

const useNotifications = () => useContext(Context);

export const NotificationsConsumer = Context.Consumer;

export default useNotifications;
