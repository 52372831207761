import React from "react";
import * as Yup from "yup";
import Field from "components/form-field";
import Form from "components/form";
import Fieldset from "components/form-fieldset";
import CaptchaField from "components/captcha-field";

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  captchaToken: "",
};

const RegisterForm = ({
  initialValues = defaultValues,
  captchaSiteKey,
  onSubmit,
  autoFocus = false,
}) => {
  return (
    <Form
      testId="register-form"
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
      submitButtonLabel="REGISTER"
      render={() => {
        return (
          <Fieldset>
            <Field
              autoFocus={autoFocus}
              name="firstName"
              type="text"
              label="FIRST NAME"
              autoComplete="given-name"
            />
            <Field
              name="lastName"
              type="text"
              label="LAST NAME"
              autoComplete="family-name"
            />
            <Field
              name="email"
              type="email"
              label="EMAIL"
              autoComplete="username"
            />
            <Field
              name="password"
              type="password"
              label="PASSWORD"
              autoComplete="new-password"
            />
            <CaptchaField siteKey={captchaSiteKey} name="captchaToken" />
          </Fieldset>
        );
      }}
    />
  );
};

export default RegisterForm;
