import React, { useState } from "react";
import PrimaryButton from "components/button/primary";
import ChevronIcon from "components/icons/chevron";

const LinkButton = ({
  children,
  href,
  buttonElement = PrimaryButton,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);

  const Button = buttonElement;

  return (
    <Button
      submitting={clicked}
      element="a"
      href={href}
      onClick={() => setClicked(true)}
      icon={<ChevronIcon />}
      {...props}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
