import React from "react";
import styles from "./form-page.module.css";

const FormPage = ({ header, children }) => {
  return (
    <div className={styles.container}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default FormPage;
