import React from "react";
import AnimatedSuccessIcon from "components/animated-icons/success";
import styles from "./form-submit-success.module.css";

const FormSubmitSuccess = props => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <AnimatedSuccessIcon />
      </div>
      <div className={styles.children}>{props.children}</div>
    </div>
  );
};

export default FormSubmitSuccess;
