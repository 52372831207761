import React, { useEffect, useCallback, useState, useRef } from "react";
import debounce from "lodash/debounce";
import MultiSelectAutoComplete from "components/multi-select-auto-complete";
import { captureException } from "utils/sentry";

const defaultDataAdapter = result => result.data;

const defaultEntityAdapter = entity => {
  return { value: entity.id, text: entity.name, data: entity };
};

const EntityAutoCompleteField = ({
  placeholder,
  form,
  field,
  id,
  fetchData,
  fetchEntity,
  entityAdapter = defaultEntityAdapter,
  dataAdapter = defaultDataAdapter,
  multiSelect = false,
  minCharacters,
  maxItems,
  renderItem
}) => {
  const [isFetchingSuggestions, setFetchingSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const initialised = useRef(false);
  const { value = [], name } = field;

  const { setFieldValue } = form;

  const handleSearchTermChange = useCallback(
    debounce(async value => {
      setFetchingSuggestions(true);
      try {
        const result = await fetchData(value);
        const suggestionsWithoutSelected = dataAdapter(result)
          .map(entity => entityAdapter(entity))
          .filter(entity => !selectedItems.find(e => e.value === entity.value));

        setSuggestions(suggestionsWithoutSelected);
      } catch (e) {
        captureException(e);
      } finally {
        setFetchingSuggestions(false);
      }
    }, 300),
    [selectedItems]
  );

  useEffect(() => {
    const fetchEntities = ids => ids.map(async id => await fetchEntity(id));

    const getSelectedEntities = async ids => {
      try {
        const entities = await Promise.all(fetchEntities(ids));
        setSelectedItems(entities.map(entity => entityAdapter(entity)));
      } catch (e) {
        captureException(e);
      }
    };

    if (value && !initialised.current) {
      if (multiSelect) {
        setSelectedItems(value.map(v => ({ value: v })));
        getSelectedEntities(value);
      } else {
        getSelectedEntities([value]);
      }
    }
  }, [value, initialised, fetchEntity, entityAdapter, multiSelect]);

  useEffect(() => {
    if (!initialised.current && minCharacters === 0) {
      handleSearchTermChange("");
    }
  }, [minCharacters, initialised, handleSearchTermChange]);

  useEffect(() => {
    if (multiSelect) {
      setFieldValue(name, selectedItems.map(i => i.value));
    } else {
      setFieldValue(name, selectedItems.length ? selectedItems[0].value : null);
    }
  }, [selectedItems, name, setFieldValue, multiSelect]);

  useEffect(() => {
    if (initialised.current && !multiSelect && !selectedItems.length) {
      handleSearchTermChange("");
    }
  }, [initialised, selectedItems, multiSelect, handleSearchTermChange]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
    }
  }, [initialised]);

  return (
    <div>
      <MultiSelectAutoComplete
        id={id}
        loading={isFetchingSuggestions}
        value={searchTerm}
        suggestions={suggestions}
        selectedItems={selectedItems}
        placeholder={placeholder}
        maxItems={multiSelect ? maxItems : 1}
        minCharacters={minCharacters}
        onChange={value => {
          if (value.trim() !== "") {
            setFetchingSuggestions(true);
            handleSearchTermChange(value);
          }
          setSearchTerm(value);
        }}
        onSelect={item => {
          setSearchTerm("");
          if (!selectedItems.find(i => i.value === item.value)) {
            setSelectedItems([...selectedItems, item]);
            setSuggestions(s => s.filter(sug => sug.value !== item.value));
          }
        }}
        onRemove={item => {
          setSelectedItems(selectedItems.filter(i => i.value !== item.value));
        }}
        renderItem={renderItem}
      />
    </div>
  );
};

export default EntityAutoCompleteField;
