import React from "react";
import Logo from "components/icons/logo";
import Dropdown from "components/dropdown";
import HeaderMenuButton from "components/button/header-menu";
import DropdownMenu from "components/dropdown-menu";
import UserIcon from "components/icons/user";
import styles from "./header-navigation.module.css";

const HeaderNavigation = (props) => {
  const {
    branding = "Organiser",
    isAuthenticated = false,
    accountName,
    accountId,
    userName,
    canEditAccount = false,
    isStaff = false,
    accountCount = 0,
  } = props;

  const accountItems = [
    {
      item: <a href={`/`}>My Events</a>,
      show: !isStaff,
    },
    {
      item: <a href={`/events/create`}>Create Event</a>,
      show: canEditAccount,
    },
    {
      item: <a href={`/accounts/${accountId}/update`}>Account Settings</a>,
      show: canEditAccount,
    },
    {
      item: (
        <a href={`/accounts/${accountId}/bank-account-details`}>
          Payment Methods
        </a>
      ),
      show: canEditAccount,
    },
    {
      item: (
        <a href={`/dashboard/ticket-shops`}>Web and Facebook integration</a>
      ),
      show: canEditAccount,
    },
    {
      item: <a href="/user/unset-account">Change Account</a>,
      show: canEditAccount && !isStaff && accountCount > 1,
    },
    {
      item: (
        <a href="/user/unset-account?next=/create-new-account">
          Create new Account
        </a>
      ),
      show: !isStaff,
    },
    {
      item: <a href="/user/set-account">Log out</a>,
      show: isStaff,
    },
  ];

  return (
    <>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.top}>
            <div className={styles.logo}>
              <a href="/">
                <Logo />
              </a>
              {!accountId && (
                <span className={styles.branding}>{branding}</span>
              )}
            </div>

            {isAuthenticated && (
              <div className={styles.children}>
                <Dropdown
                  button={
                    <HeaderMenuButton
                      icon={
                        <UserIcon color={isStaff ? "#3273dc" : "#282E34"} />
                      }
                    >
                      {userName}
                    </HeaderMenuButton>
                  }
                >
                  <DropdownMenu
                    items={[
                      <a href="/user/update-profile">Update Profile</a>,
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fixr.co"
                      >
                        Buy Tickets
                      </a>,
                      <a href="/user/logout">Log out</a>,
                    ]}
                  />
                </Dropdown>
              </div>
            )}
          </div>
          {isAuthenticated && accountId && (
            <div
              className={styles.account}
              data-testid="header-account-navigation"
            >
              <span className={styles.accountSeparator} />
              <Dropdown
                button={
                  <HeaderMenuButton data-testid="header-account-button">
                    {accountName}
                  </HeaderMenuButton>
                }
              >
                <DropdownMenu
                  items={accountItems
                    .filter((i) => i.show === true)
                    .map((i) => i.item)}
                />
              </Dropdown>
            </div>
          )}
        </header>
      </div>
    </>
  );
};

export default HeaderNavigation;
