import React, { useState, useCallback } from "react";
import classnames from "classnames";
import Tooltip from "components/tooltip";
import styles from "./help.module.css";

const HelpButton = ({ children }) => {
  const [isHelpOpen, setHelpOpen] = useState(false);

  return (
    <Tooltip
      content={children}
      onShow={useCallback(() => {
        setHelpOpen(true);
      }, [])}
      onHide={useCallback(() => {
        setHelpOpen(false);
      }, [])}
    >
      <button
        type="button"
        className={classnames({
          [styles.helpButton]: true,
          [styles.open]: isHelpOpen
        })}
      >
        ?
      </button>
    </Tooltip>
  );
};

export default HelpButton;
