import React from "react";
import EntityCreateUpdate from "containers/entity-create-update";
import UserDetailsForm from "components/forms/user-details";
import { getCurrentUser, updateCurrentUser } from "api/users";
import useNotifications from "components/notifications";

const dataAdapter = data => {
  const { firstName, lastName, email, userProfile } = data;

  const userProfileFields = {
    mobileNumber: userProfile.mobileNumber,
    dateOfBirth: userProfile.dateOfBirth,
    sex: userProfile.sex
  };

  return {
    firstName,
    lastName,
    email,
    userProfile: userProfileFields
  };
};

const EditUserDetailsContainer = ({ id }) => {
  const { notifySuccess } = useNotifications();

  return (
    <EntityCreateUpdate
      id={id}
      form={UserDetailsForm}
      get={getCurrentUser}
      update={updateCurrentUser}
      entityName="DETAIL"
      onSubmitted={(result, { setSubmitting }) => {
        setSubmitting(false);
        notifySuccess("Details updated.");
      }}
      dataAdapter={dataAdapter}
    />
  );
};

export default EditUserDetailsContainer;
