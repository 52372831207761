import React, { useState, useCallback, useEffect } from "react";
import classnames from "classnames";
import { useDropzone } from "react-dropzone";
import loadImage from "blueimp-load-image";
import "blueimp-canvas-to-blob";
import Modal from "components/modal/";
import PrimaryButton from "components/button/primary";
import FormFieldReset from "components/form-field-reset";
import SecondaryButton from "components/button/secondary";
import useImageApi from "hooks/use-image-api";
import useNotifications from "components/notifications";
import Cropper from "components/cropper";
import styles from "./image-field.module.css";

const NOTIFICATION_TAG = "image-field";

const ImageField = ({
  id,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  imageType,
  aspectRatio = 1,
  form,
  field
}) => {
  const [blobSource, setBlobSource] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isEditing, setEditing] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [remoteImage, setRemoteImage] = useState(null);
  const [intrinsicDimensions, setIntrinsicDimensions] = useState({
    width: 0,
    height: 0
  });

  const [crop, setCrop] = useState(null);

  const { postImage, getImageById } = useImageApi();

  const {
    notifyError,
    notifySuccess,
    notifyInfo,
    removeNotificationsByTag
  } = useNotifications();

  const clearNotifications = () => removeNotificationsByTag(NOTIFICATION_TAG);

  const onReset = () => {
    form.setFieldValue(field.name, null);
    setRemoteImage(null);
    setBlobSource(null);
    setFileToUpload(null);
  };

  const onSelectedFileLoaded = file => {
    loadImage(
      file,
      canvas => {
        clearNotifications();
        setIntrinsicDimensions({
          width: canvas.width,
          height: canvas.height
        });

        try {
          canvas.toBlob(
            blob => {
              setBlobSource(URL.createObjectURL(blob));
              setFileToUpload(new File([blob], "upload.jpeg"));
              setEditing(true);
            },
            "image/jpeg",
            0.95
          );
        } catch (e) {
          notifyError(e.message);
          setProcessing(false);
        }
      },
      {
        orientation: true,
        maxWidth: 1600,
        canvas: true,
        downsamplingRatio: 0.5,
        contain: true
      }
    );
  };

  const onFileSelected = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setProcessing(true);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        onSelectedFileLoaded(acceptedFiles[0], reader.result);
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, []);

  const onSaveSuccess = result => {
    setEditing(false);
    setProcessing(false);
    clearNotifications();
    notifySuccess("Image uploaded.");
    form.setFieldValue(field.name, result.id);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileSelected,
    onDropRejected: () =>
      notifyInfo(
        "Image format not supported. Please convert to JPEG, PNG, SVG or WEBP."
      ),
    accept:
      "image/jpeg, image/png, image/svg+xml, image/webp, image/gif, image/bmp"
  });

  useEffect(() => {
    const fetchImage = async id => {
      const image = await getImageById(id);
      setRemoteImage(image);
    };

    if (field.value) {
      fetchImage(field.value);
    }
  }, [field.value, getImageById]);

  const submitImage = async () => {
    const { x, y, width, height } = crop;

    setSubmitting(true);
    clearNotifications();
    try {
      const result = await postImage({
        x: Math.round((x / 100) * intrinsicDimensions.width),
        y: Math.round((y / 100) * intrinsicDimensions.height),
        width: Math.round((width / 100) * intrinsicDimensions.width),
        height: Math.round((height / 100) * intrinsicDimensions.height),
        image: fileToUpload,
        imageType
      });
      setSubmitting(false);
      onSaveSuccess(result);
    } catch (e) {
      notifyError(
        <>
          {e.message} <button onClick={() => submitImage()}>Retry?</button>
        </>,
        { tag: NOTIFICATION_TAG }
      );
      setSubmitting(false);
      throw e;
    }
  };

  const showInput = !field.value && !remoteImage;

  return (
    <div className={styles.container}>
      <Modal isOpen={blobSource && isEditing}>
        {blobSource && (
          <div className={styles.modal}>
            <div className={styles.instructions}>
              <h3>Create thumbnail</h3>This will be used when sharing on social
              media, or on one of our listing pages.
            </div>
            <div className={styles.cropperContainer}>
              <Cropper
                disabled={isSubmitting}
                minWidth={50}
                minHeight={50}
                maxWidth={maxWidth}
                maxHeight={maxHeight}
                keepSelection
                id={id}
                src={blobSource}
                crop={crop}
                onChange={(pixelCrop, percentCrop) => {
                  setCrop(percentCrop);
                }}
                className={styles.cropper}
                onImageLoaded={image => {
                  const h = Math.min(
                    (image.width - 32) / aspectRatio,
                    image.height - 32
                  );
                  const w = h * aspectRatio;
                  const x = (image.width - w) / 2;
                  const y = (image.height - h) / 2;

                  setCrop({
                    unit: "%",
                    x: (x / image.width) * 100,
                    y: (y / image.height) * 100,
                    width: (w / image.width) * 100,
                    height: (h / image.height) * 100,
                    aspect: aspectRatio
                  });

                  return false;
                }}
              />
            </div>
            <div className={styles.modalActions}>
              <SecondaryButton
                onClick={() => {
                  setProcessing(false);
                  setEditing(false);
                }}
              >
                CANCEL
              </SecondaryButton>
              <PrimaryButton onClick={submitImage} submitting={isSubmitting}>
                SAVE
              </PrimaryButton>
            </div>
          </div>
        )}
      </Modal>
      <div
        className={styles.frame}
        style={{ paddingBottom: `${100 / aspectRatio}%` }}
      >
        {showInput && (
          <div
            {...getRootProps()}
            className={classnames({
              [styles.droparea]: true,
              [styles.dragging]: isDragActive
            })}
          >
            <input id={id} {...getInputProps()} />
            <div className={styles.instructions}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="31"
                viewBox="0 0 41 31"
              >
                <path
                  fill="#7F7F7F"
                  fillRule="evenodd"
                  d="M19.79 27.23H4.5l7.87-14.73 7.42 14.73zm16.17 0h-10.2l5.02-5.18 5.18 5.18zM3.5 3h33.85c.27 0 .5.22.5.5v21.39l-6.03-6.04c-.28-.28-.66-.41-1.07-.43-.41 0-.79.16-1.07.45l-7.07 7.3-8.87-17.6c-.25-.5-.76-.82-1.32-.83-.57.02-1.08.3-1.34.8L3 23.67V3.5c0-.28.23-.5.5-.5zm33.85-3H3.5C1.57 0 0 1.57 0 3.5v23.23c0 1.07.49 2.01 1.24 2.65.03.03.06.06.09.08.6.48 1.35.77 2.17.77h33.85c1.93 0 3.5-1.57 3.5-3.5V3.5c0-1.93-1.57-3.5-3.5-3.5z"
                />
              </svg>
              <PrimaryButton
                size="small"
                submitting={isProcessing}
                tabIndex="-1"
              >
                UPLOAD IMAGE
              </PrimaryButton>
            </div>
          </div>
        )}
        {remoteImage && !showInput && (
          <div className={styles.remoteImage}>
            <img alt="" src={remoteImage.croppedImage || remoteImage.image} />
            <FormFieldReset onClick={onReset}>Reset</FormFieldReset>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageField;
