import { post, patch, get } from "./";
import endpoints from "../constants/endpoints";

const endpoint = endpoints.accounts;

export async function createAccount(data) {
  return post(endpoint, data);
}

export async function updateAccount({ id, ...data }) {
  return patch(`${endpoint}/${id}`, data);
}

export async function getAccount(id) {
  return get(`${endpoint}/${id}`);
}

export async function getAccounts(searchTerm) {
  return get(`${endpoint}?search=${searchTerm || ""}`);
}
