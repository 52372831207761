import React, { useMemo } from "react";
import Place from "components/place";

const LocationField = ({ field, form, readonly, onResetClicked, ...props }) => {
  const name = field.name;
  const value = field.value;

  const position = useMemo(
    () => ({
      lat: (value && value.latitude) || 0,
      lng: (value && value.longitude) || 0
    }),
    [value]
  );

  return (
    <Place
      position={position}
      draggable={!readonly}
      aspectRatio={readonly ? 30 / 15 : 1}
      zoom={readonly ? 12 : 1}
      hideAddress
      onResetClicked={onResetClicked}
      onDragEnd={pos => {
        form.setFieldValue(`${name}.latitude`, pos.lat);
        form.setFieldValue(`${name}.longitude`, pos.lng);
      }}
    />
  );
};

export default LocationField;
