import React from "react";
import IconButton from "components/button/icon";
import useCopyToClipboard from "hooks/use-copy-to-clipboard";

const defaultButton = props => (
  <IconButton {...props}>
    <svg
      fill="#CC003E"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </svg>
  </IconButton>
);

const CopyToClipboardButton = ({ text, message, button }) => {
  const copyToClipboard = useCopyToClipboard();
  const ButtonComp = button || defaultButton;

  return (
    <ButtonComp
      type="button"
      onClick={() => copyToClipboard(text, message)}
    ></ButtonComp>
  );
};

export default CopyToClipboardButton;
