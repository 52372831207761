import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import PrimaryButton from "components/button/primary";
import Field from "components/form-field";
import Submit from "components/form-submit";
import Form from "components/form";

const ReportSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  comments: Yup.string().required("Required")
});

const defaultValues = {
  name: "",
  email: "",
  comments: ""
};

const ReportErrorForm = ({ initialValues = defaultValues, onSubmit }) => {
  const [validateOnChange, setValidateOnChange] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReportSchema}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
      onSubmit={onSubmit}
      render={({ isSubmitting, isValid, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Field autoFocus name="name" type="text" label="NAME" />
            <Field name="email" type="email" label="EMAIL" />
            <Field
              name="comments"
              component="textarea"
              label="WHAT HAPPENED?"
            />
            <Submit>
              <PrimaryButton
                submitting={isSubmitting}
                shake={isSubmitting && !isValid}
                type="submit"
                onClick={() => setValidateOnChange(true)}
              >
                SUBMIT
              </PrimaryButton>
            </Submit>
          </Form>
        );
      }}
    />
  );
};

export default ReportErrorForm;
