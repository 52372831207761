import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const Card = ({ children, color }) => {
	return (
		<div className="Card">
			<div style={{ background: color }} className="Card__handle" />
			{children}
		</div>
	);
};

Card.propTypes = {
	color: PropTypes.string
};

Card.defaultProps = {
	color: '#6e6e6e'
};

export default Card;
