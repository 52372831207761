import React, { useState, useEffect, useCallback, useRef } from "react";
import format from "date-fns/format";
import parse from "date-fns/parse";
import parseISO from "date-fns/parseISO";
import DatePicker from "components/date-picker";
import styles from "./date-field.module.css";

const DateField = ({
  id,
  field,
  form,
  onChange = (isoDate = "", dateString = "") => {},
  minDateIso,
  maxDateIso
}) => {
  const [dateString, setDateString] = useState(field.value || null);

  const mounted = useRef(false);
  const memoisedOnChange = useCallback(onChange, []);
  const { setFieldValue } = form;

  useEffect(() => {
    if (mounted.current) {
      const isoDate = `${dateString}T00:00`;
      memoisedOnChange(isoDate, dateString);
      setFieldValue(field.name, dateString);
    }
  }, [dateString, memoisedOnChange, setFieldValue, field.name]);

  useEffect(() => {
    mounted.current = true;
  }, []);

  const dateWithoutTime = dateString
    ? parse(dateString, "yyyy-MM-dd", new Date())
    : null;

  const minDate = minDateIso ? parseISO(minDateIso) : null;
  const maxDate = maxDateIso ? parseISO(maxDateIso) : null;

  return (
    <div className={styles.container}>
      <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        id={id}
        selected={dateWithoutTime}
        onChange={date => {
          try {
            const dateString = format(date, "yyyy-MM-dd");
            if (dateString.length <= 10) {
              setDateString(dateString);
            } else {
              setDateString(null);
            }
          } catch (e) {
            setDateString(null);
          }
        }}
      />
    </div>
  );
};

export default DateField;
