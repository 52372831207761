import React, { useState } from "react";
import ResetPassword from "components/reset-password";
import { resetPassword } from "api/user";
import { submitForm } from "utils/form";

const ResetPasswordContainer = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <ResetPassword
      submitted={submitted}
      onSubmit={async (values, formikBag) =>
        setSubmitted(await submitForm(resetPassword)(values, formikBag))
      }
    />
  );
};

export default ResetPasswordContainer;
