import React from "react";
import * as Yup from "yup";
import Field from "components/form-field";
import Form from "components/form";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
});

const defaultValues = {
  email: ""
};

const ResetPasswordForm = ({ initialValues = defaultValues, onSubmit }) => {
  return (
    <Form
      initialValues={initialValues}
      validationSchema={ResetPasswordSchema}
      onSubmit={onSubmit}
      render={() => {
        return (
          <Field
            autoFocus
            name="email"
            type="text"
            label="EMAIL"
            autoComplete="username"
          />
        );
      }}
    />
  );
};

export default ResetPasswordForm;
