import React from "react";
import styles from "./block-loader.module.css";

const BlockLoader = props => {
  return <div className={styles.container}>{props.children}</div>;
};

export const Line = props => (
  <div data-element="line" {...props} className={styles.line}></div>
);

export default BlockLoader;
