import React, { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const Cropper = ({ crop: cropFromProps, onChange, ...props }) => {
  const [crop, setCrop] = useState(cropFromProps);
  const [pixelCrop, setPixelCrop] = useState(null);

  useEffect(() => {
    setCrop(cropFromProps);
  }, [cropFromProps]);

  return (
    <ReactCrop
      {...props}
      crop={crop}
      onChange={(crop, percentCrop) => {
        setCrop(percentCrop);
        setPixelCrop(crop);
      }}
      onDragEnd={() => {
        onChange(pixelCrop, crop);
      }}
    />
  );
};

export default Cropper;
