import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import Portal from "components/portal";
import useOnResize from "hooks/use-on-resize";
import styles from "./input-dropdown.module.css";

export const DropdownItem = ({ className = "", children }) => {
  return (
    <div
      className={classnames({ [styles.item]: true, [className]: className })}
      data-element="dropdown-item"
    >
      {children}
    </div>
  );
};

export const DropdownExtraItem = ({ children }) => {
  return <div className={styles.extra}>{children}</div>;
};

const Wrap = ({ children, usePortal }) =>
  usePortal ? <Portal className={styles.portal}>{children}</Portal> : children;

const InputDropdown = ({
  children,
  selectedIndex = -1,
  isOpen,
  renderItem = item => <DropdownItem>{item.text}</DropdownItem>,
  onSelect,
  items,
  sizeRef,
  offsetY = 2,
  usePortal = true
}) => {
  const [sizeStyles, setSizeStyles] = useState(null);
  const itemsContainerRef = useRef(null);
  const selectedRef = useRef(null);
  const windowSize = useOnResize();

  useEffect(() => {
    const setStyles = rect => {
      const documentOffsetY = Math.abs(document.documentElement.offsetTop);
      setSizeStyles({
        top:
          window.scrollY + rect.top + rect.height + offsetY + documentOffsetY,
        left: rect.left,
        width: rect.width
      });
    };
    if (isOpen && usePortal) {
      setStyles(sizeRef.current.getBoundingClientRect());
    }
  }, [isOpen, sizeRef, windowSize, offsetY, usePortal]);

  useEffect(() => {
    if (selectedRef.current) {
      const parentHeight = itemsContainerRef.current.getBoundingClientRect()
        .height;
      const itemHeight = selectedRef.current.getBoundingClientRect().height;
      const itemOffsetTop = selectedRef.current.offsetTop;

      if (itemOffsetTop >= parentHeight + itemsContainerRef.current.scrollTop) {
        itemsContainerRef.current.scrollTop =
          itemOffsetTop - parentHeight + itemHeight;
      } else if (itemOffsetTop < itemsContainerRef.current.scrollTop) {
        itemsContainerRef.current.scrollTop = itemOffsetTop;
      }
    }
  }, [selectedIndex, selectedRef, itemsContainerRef]);

  return (
    <Wrap className={styles.portal} usePortal={usePortal}>
      {isOpen && (
        <div className={styles.container} style={sizeStyles}>
          <div className={styles.items} ref={itemsContainerRef}>
            {items.map((item, i) => {
              const isSelected = selectedIndex === i;

              return (
                <div
                  ref={isSelected ? selectedRef : null}
                  className={styles.itemContainer}
                  key={i}
                  onMouseDown={e => {
                    e.preventDefault();
                    onSelect(item);
                  }}
                  data-has-focus={isSelected}
                >
                  {renderItem(item)}
                </div>
              );
            })}
          </div>
          {items.length === 0 && (
            <div className={styles.empty}>No results found</div>
          )}
          {children}
        </div>
      )}
    </Wrap>
  );
};

export default InputDropdown;
