import { get } from "./";
import endpoints from "../constants/endpoints";

export async function getHubs(searchTerm) {
  return get(`${endpoints.hubs}?search=${searchTerm || ""}`);
}

export async function getHub(id) {
  return get(`${endpoints.hubs}/${id}`);
}
