import { post } from "./";
import endpoints from "../constants/endpoints";

export async function submitFeedback({ sentryDsn, ...data }) {
  const endpoint = `${endpoints.sentryUserFeedback}`;
  const headers = {
    "content-type": "application/json",
    Authorization: `DSN ${sentryDsn}`
  };
  return post(endpoint, data, headers);
}
