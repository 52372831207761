import React from "react";
import ChevronDownIcon from "components/icons/chevron-down";
import Button from "components/button";

import styles from "./header-menu.module.css";

const HeaderMenuButton = ({
  icon,
  children,
  className = "",
  submitting = false,
  disabled = false,
  shake = false,
  ...props
}) => {
  return (
    <div className={styles.container}>
      <Button {...props}>
        {icon && icon}
        <span>{children}</span>
        <ChevronDownIcon />
      </Button>
    </div>
  );
};

export default HeaderMenuButton;
