import React, { useState } from "react";
import Form from "components/form";
import * as Yup from "yup";
import Field from "components/form-field";
import ImageField from "components/image-field";
import DateTimeField from "components/date-time-field";
import BooleanField from "components/boolean-field";
import Fieldset from "components/form-fieldset";
import VenueField from "components/venue-field";
import FormFieldToggle from "components/form-field-toggle";
import useTags from "hooks/use-tags";
import useEntryConditions from "hooks/use-entry-conditions";
import MultiSelectField from "components/multi-select-field";
import SalesAccountField from "components/sales-account-field";
import HubsField from "components/hubs-field";
import Auth from "components/auth";
import RadioGroupField from "components/radio-group-field";

const EventSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  openTime: Yup.string().nullable().required("Required"),
  closeTime: Yup.string().nullable().required("Required"),
  venueId: Yup.number().nullable().required("Required"),
});

export const defaultValues = {
  name: "",
  description: "",
  openTime: "",
  closeTime: "",
  lastEntry: null,
  entryConditions: [],
  tags: [],
  image: "",
  refundsAllowed: true,
  locationType: "Venue",
  ticketTransfersAllowed: true,
  ticketTransfersDeadline: null,
  isPrivate: false,
  hubs: null,
  venueId: "",
};

const EventForm = ({
  initialValues = defaultValues,
  submitButtonLabel,
  autoFocus = false,
  onSubmit,
}) => {
  const [minOpenDateIso, setMinOpenDateIso] = useState(initialValues.openTime);
  const [maxLastEntryTimeIso, setMaxLastEntryTimeIso] = useState(
    initialValues.closeTime
  );
  const [selectedVenue, setSelectedVenue] = useState(null);
  const { tagsOptions } = useTags();
  const { entryConditionsOptions } = useEntryConditions();

  return (
    <Form
      testId="event-form"
      initialValues={initialValues}
      validationSchema={EventSchema}
      submitButtonLabel={submitButtonLabel}
      onSubmit={onSubmit}
      render={({ setFieldValue, errors, values, unregisterField }) => {
        const isOnlineEvent =
          values.locationType === "Online" || selectedVenue?.online;

        return (
          <>
            <Fieldset>
              <Auth permissions="fixrcms.staff">
                <Field
                  name="salesAccount"
                  placeholder="Search"
                  component={SalesAccountField}
                  label="SALES ACCOUNT"
                  isAdminField
                  validate={(value) => {
                    if (!value) {
                      return "Required.";
                    }
                  }}
                />
              </Auth>
              <Field
                autoFocus={autoFocus}
                name="name"
                type="text"
                label="EVENT NAME"
                placeholder="A short title for your event"
                autoComplete="off"
              />
              <Field
                optional
                name="description"
                component="textarea"
                label="DESCRIPTION"
                placeholder="Write a description of your event to let attendees know what to expect."
              />
              <Field
                name="image"
                label="EVENT IMAGE"
                aspectRatio={2 / 1}
                minWidth={200}
                minHeight={100}
                component={ImageField}
                optional
              />
            </Fieldset>

            <Fieldset legend="Location">
              <Field
                inline
                hideLabel
                name="locationType"
                value={isOnlineEvent ? "Online" : "Venue"}
                component={RadioGroupField}
                onChange={(e) => {
                  setFieldValue("locationType", e);
                  setSelectedVenue(null);
                  setFieldValue("venueId", "");
                }}
                options={["Venue", "Online"]}
                help={
                  <>
                    <p>For online events no venue details are required.</p>
                    <p>
                      Simply select your event’s timezone to allow your global
                      audience to join your event at the right time.
                    </p>
                    <p>
                      The livestream link to your virtual event will be included
                      as part of the event ticket.
                    </p>
                  </>
                }
              />
              <Field
                testId="event-form__venue"
                isOnline={isOnlineEvent}
                name="venueId"
                label={isOnlineEvent ? "TIMEZONE" : "VENUE"}
                component={VenueField}
                onVenueLoaded={setSelectedVenue}
                onChange={(value) => {
                  if (!value) {
                    setSelectedVenue(null);
                  }
                }}
              ></Field>
            </Fieldset>

            <Fieldset
              legend="Date"
              badge={selectedVenue ? `${selectedVenue.timezone}` : null}
            >
              <Field
                testId="event-form__openTime"
                name="openTime"
                label="EVENT STARTS"
                component={DateTimeField}
                onChange={(isoDate, dateString, timeString) => {
                  if (dateString) {
                    setMinOpenDateIso(`${dateString}T${timeString || "00:00"}`);
                  }
                }}
              />
              <Field
                testId="event-form__closeTime"
                name="closeTime"
                label="EVENT ENDS"
                onChange={(isoDate, dateString, timeString) => {
                  if (dateString) {
                    setMaxLastEntryTimeIso(
                      `${dateString}T${timeString || "23:59"}`
                    );
                  }
                }}
                component={DateTimeField}
                minDateIso={minOpenDateIso}
              />
            </Fieldset>

            <Fieldset legend="Entry Requirements" badge="optional">
              <FormFieldToggle
                label="Last entry time"
                initiallyVisible={values.lastEntry}
                hasErrors={!!errors.lastEntry}
                onHide={() => {
                  setFieldValue("lastEntry", null);
                }}
                onShow={() => {
                  setFieldValue("lastEntry", maxLastEntryTimeIso);
                }}
              >
                <Field
                  name="lastEntry"
                  hideLabel
                  component={DateTimeField}
                  minDateIso={minOpenDateIso}
                  maxDateIso={maxLastEntryTimeIso}
                />
              </FormFieldToggle>
              <FormFieldToggle
                label="Entry conditions"
                focusOnShow
                initiallyVisible={values.entryConditions.length > 0}
                onHide={() => {
                  setFieldValue("entryConditions", []);
                }}
              >
                <Field
                  name="entryConditions"
                  initiallyOpen={values.entryConditions.length === 0}
                  hideLabel
                  component={MultiSelectField}
                  editLabel="EDIT"
                  options={entryConditionsOptions}
                />
              </FormFieldToggle>
            </Fieldset>

            <Fieldset legend="Tags" badge="OPTIONAL">
              {(!values.tags || !values.tags.length) && (
                <p style={{ marginBottom: "-32px" }}>
                  Help customers find your event by adding searchable keywords,
                  eg: festival, live music, food &amp; drink, charity, sports,
                  student, quiz, etc.
                </p>
              )}
              <Field
                name="tags"
                hideLabel
                component={MultiSelectField}
                editLabel="EDIT TAGS"
                options={tagsOptions}
              />
            </Fieldset>

            <Fieldset legend="Preferred audience" badge="OPTIONAL">
              <Field
                name="hubs"
                help="This helps promote your event in specific places."
                placeholder="Search"
                component={HubsField}
                label="CITIES AND UNIVERSITIES"
              />
            </Fieldset>

            <Fieldset legend="Settings" badge="optional">
              <Field
                inline
                name="refundsAllowed"
                label="Allow refunds"
                hideLabel
                component={BooleanField}
                help="Check this box if you would like us to automatically approve all ticket refund requests."
              />
              <FormFieldToggle
                label="Allow ticket transfers"
                initiallyVisible={values.ticketTransfersAllowed}
                hasErrors={
                  !!errors.ticketTransfersAllowed ||
                  !!errors.ticketTransfersDeadline
                }
                onHide={() => {
                  setFieldValue("ticketTransfersAllowed", false);
                  setFieldValue("ticketTransfersDeadline", null);
                }}
                onShow={() => {
                  setFieldValue("ticketTransfersAllowed", true);
                }}
                help="This will allow your customers to transfer some or all of their tickets to a friend.  It means that you'll create more customers and your customer data will be more accurate."
              >
                <Field
                  name="ticketTransfersDeadline"
                  label="TRANSFER DEADLINE"
                  component={DateTimeField}
                />
              </FormFieldToggle>
              <Field
                inline
                name="isPrivate"
                label="Event is private"
                hideLabel
                component={BooleanField}
                help="A private event will not be discoverable by search.  You will need to send your event link directly to your customers."
              />
            </Fieldset>
          </>
        );
      }}
    />
  );
};

export default EventForm;
