const events = {
  userCreated: "organiser_user_created",
  eventCreated: "organiser_event_created",
  salesAccountCreated: "organiser_sales_account_created",
  ticketTypeCreated: "organiser_ticket_type_created",
  eventMadeLive: "organiser_event_made_live"
};

export const push = data => {
  if (!window.dataLayer) {
    console.warn(
      "Tag Mangager util: No datalayer found. Make sure google tag manager snippet is on page"
    );
    return null;
  }

  const tagFired = window.dataLayer.push(data) === false;

  if (!tagFired) {
    console.warn(`Tag Mangager util: No tag fired for event ${data.event}`);
  }

  return tagFired;
};

export default {
  push,
  events
};
