import { useCallback } from "react";
import { getImage, createImage, cropImage } from "api/images";

const useImageApi = () => {
  const postImage = async ({
    image = null,
    x = 0,
    y = 0,
    width = 0,
    height = 0,
    imageType = "Event_Main"
  } = {}) => {
    try {
      const { id } = await createImage({ image });
      const cropResult = await cropImage(id, {
        x,
        y,
        width,
        height,
        imageType
      });
      return cropResult;
    } catch (e) {
      throw e;
    }
  };

  const getImageById = useCallback(id => getImage(id), []);

  return { postImage, getImageById };
};

export const imageTypes = {
  eventMain: "eventMain",
  venueLogo: "venueLogo",
  organiserLogo: "organiserLogo"
};

export default useImageApi;
