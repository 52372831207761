import React from "react";
import Button from "../";
import classnames from "classnames";

const CopyButton = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animating: false
    };
  }

  handleOnClick = () => {
    this.setState({ animating: true });
    this.props.onClick();
    setTimeout(() => {
      this.setState({ animating: false });
    }, 2000);
  };

  render() {
    const { props } = this;
    const { animating } = this.state;

    return (
      <Button
        {...props}
        className={classnames({
          "Button--Copy": true,
          "Button--Copy--animating": animating
        })}
        onClick={this.handleOnClick}
      >
        <span>{props.children}</span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.4 421.9">
          <path
            d="M472 24.1l-13.1-11.3c-21.8-18.7-54.6-16.6-73.8 4.7L165.4 261.1 99.1 204c-21.8-18.8-54.5-16.7-73.8 4.6l-11.6 12.8C4.3 231.8-.6 245.5.1 259.6c.7 14 7 27.2 17.4 36.6L139 407.5c10.5 9.9 24.6 15 39 14.3 14.4-.8 27.9-7.4 37.3-18.3L477.6 98.7c9.2-10.6 13.7-24.5 12.7-38.5-1.1-14-7.6-27-18.3-36.1z"
            fill="#fff"
          />
        </svg>
      </Button>
    );
  }
};

export default CopyButton;
