import React from "react";
import SubmitButton from "components/button/submit";
import classnames from "classnames";
import styles from "./primary.module.css";

const PrimaryButton = props => {
  return (
    <SubmitButton
      className={classnames({
        [styles.primary]: true
      })}
      {...props}
    >
      {props.children}
    </SubmitButton>
  );
};

export default PrimaryButton;
