import React from 'react';
import './styles.css';
import accounting from 'accounting';

const PrettyAmount = ({ amount, currency = 'GBP', locale = 'en-GB' }) => {
	const symbols = {
		GBP: '£',
		USD: '$',
		EUR: '€'
	};

	const amountAsArray = accounting
		.formatMoney(amount, {
			symbol: symbols[currency] || ''
		})
		.split('.');

	return (
		<span className="pretty-amount">
			{amountAsArray[0]}
			<span className="pretty-amount__fraction">.{amountAsArray[1]}</span>
		</span>
	);
};

export default PrettyAmount;
