import React from "react";
import { useState, useEffect } from "react";
import { getTags } from "api/tags";
import useNotifications from "components/notifications";

const toOptionsTree = tags => {
  const rootItems = [];
  const lookup = {};
  for (const tag of tags) {
    const tagId = tag.id;
    const parentId = tag.parent;
    if (!Object.prototype.hasOwnProperty.call(lookup, tagId)) {
      lookup[tagId] = { options: [] };
    }
    lookup[tagId] = {
      value: tag.slug,
      text: tag.name,
      options: lookup[tagId].options
    };
    const TreeItem = lookup[tagId];

    if (parentId === null) {
      rootItems.push(TreeItem);
    } else {
      if (!Object.prototype.hasOwnProperty.call(lookup, parentId)) {
        lookup[parentId] = { options: [] };
      }
      lookup[parentId].options.push(TreeItem);
    }
  }

  return rootItems;
};

const useTags = () => {
  const [tags, setTags] = useState([]);
  const [tagsOptions, setTagsOptions] = useState([]);
  const { notifyError } = useNotifications();

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await getTags();
        setTags(result.data);
        setTagsOptions(toOptionsTree(result.data));
      } catch (e) {
        notifyError(
          <>
            Could not fetch tags. <button onClick={fetch}>Retry?</button>
          </>
        );
      }
    };

    fetch();
  }, [notifyError]);

  return { tags, tagsOptions };
};

export default useTags;
