import React, { useEffect, useState } from "react";
import useNotifications from "components/notifications";
import { submitForm } from "utils/form";
import FormLoading from "components/form-loading";
import FormContainer from "components/form-container";

const EntityUpdateCreate = ({
  id,
  get,
  create,
  update,
  form,
  submitButtonLabel,
  onSubmitted,
  autoFocus = false,
  dataAdapter,
  entityName = "",
  initialValues,
  submittedValues
}) => {
  const { notifyError } = useNotifications();

  const [fetching, setFetching] = useState(!!id);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const fetchEntity = async () => {
      try {
        const result = await get(id);
        const data = dataAdapter ? dataAdapter(result) : result;
        setValues(data);
      } catch (e) {
        notifyError(e.message || e.detail);
      } finally {
        setFetching(false);
      }
    };
    if (id) {
      fetchEntity();
    }
  }, [id, notifyError, get, dataAdapter]);

  useEffect(() => {
    // Updates any fields that should turn read-only or admin-only after submission
    if (submittedValues) {
      setValues(submittedValues);
    }
  }, [submittedValues]);

  const FormComponent = form;

  const buttonLabel = (submitButtonLabel
    ? submitButtonLabel
    : id
    ? `SAVE CHANGES`
    : `CREATE ${entityName}`
  ).trim();

  return (
    <FormContainer>
      {fetching ? (
        <FormLoading />
      ) : (
        <FormComponent
          initialValues={values}
          autoFocus={autoFocus}
          submitButtonLabel={buttonLabel}
          onSubmit={async (values, formikBag) => {
            const result = await submitForm(id ? update : create)(
              { ...values, id },
              formikBag
            );
            if (result) {
              onSubmitted && onSubmitted(result, formikBag);
            }
          }}
        />
      )}
    </FormContainer>
  );
};

export default EntityUpdateCreate;
