import React from "react";
import DatePickerComponent, { registerLocale } from "react-datepicker";
import enGB from "date-fns/locale/en-GB";
import "./date-picker.module.css";

registerLocale("en-GB", enGB);

const DatePicker = ({
  id,
  selected,
  onChange,
  placeHolder = "DD/MM/YYYY",
  dateFormat = "dd/MM/yyyy",
  locale = "en-GB",
  minDate
}) => {
  return (
    <DatePickerComponent
      autoComplete="off"
      id={id}
      minDate={minDate}
      dateFormat={dateFormat}
      shouldCloseOnSelect={true}
      locale={locale}
      selected={selected}
      onChange={onChange}
      placeholderText={placeHolder}
    />
  );
};

export default DatePicker;
