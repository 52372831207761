import React, { useState, useMemo } from "react";
import PrimaryButton from "components/button/primary";
import SlideInModal from "components/modal/variants/slide-in";
import FormContainer from "components/form-container";
import styles from "./multi-select-field.module.css";
import Checkbox from "components/checkbox";

const defaultRenderItem = item => (
  <div className={styles.item}>
    {item.data && item.data.iconUrl && (
      <img alt="" src={item.data.iconUrl} role="presentation" />
    )}
    {item.text}
  </div>
);

const filterOption = (option, filter) => {
  const searchString = filter.toLowerCase();

  const matchesRoot = option.text.toLowerCase().indexOf(searchString) > -1;
  if (matchesRoot) return option;

  const children = option.options
    ? option.options.filter(
        o => o.text.toLowerCase().indexOf(searchString) > -1
      )
    : [];

  return children.length
    ? {
        ...option,
        options: children
      }
    : null;
};

const MultiSelectField = ({
  field,
  form,
  options = [],
  editLabel = "EDIT",
  initiallyOpen = false,
  enableFiltering = true,
  renderItem = defaultRenderItem
}) => {
  const name = field.name;
  const selectedValues = field.value;
  const [isEditing, setEditing] = useState(initiallyOpen);
  const [filter, setFilter] = useState("");

  const filteredOptions = filter
    ? options.map(o => filterOption(o, filter)).filter(o => o !== null)
    : options;

  const renderOptions = option => {
    if (!option.options || !option.options.length)
      return (
        <li key={option.value}>
          <Checkbox
            id={`ms-${option.value}`}
            value={option.value}
            checked={selectedValues.indexOf(option.value) > -1}
            onChange={e => {
              const values = selectedValues.filter(v => v !== option.value);
              if (e.target.checked) {
                form.setFieldValue(name, [...values, option.value]);
              } else {
                form.setFieldValue(name, values);
              }
            }}
            label={option.text}
          />
        </li>
      );

    return (
      <li key={option.value} className={styles.parent}>
        <h3>{option.text}</h3>
        <ul>{option.options.map(o => renderOptions(o))}</ul>
      </li>
    );
  };

  const selectedOptions = useMemo(
    () =>
      selectedValues
        .map(value => {
          const stack = [...options];
          let node;

          while (stack.length > 0) {
            node = stack.pop();
            if (node.value === value) {
              return node;
            } else if (node.options && node.options.length) {
              for (let i = 0; i < node.options.length; i += 1) {
                stack.push(node.options[i]);
              }
            }
          }
          return null;
        })
        .filter(o => o),
    [selectedValues, options]
  );

  return (
    <div>
      <div className={styles.selectedOptions}>
        {selectedOptions.map(o => {
          return <div key={o.value}>{renderItem(o)}</div>;
        })}
      </div>
      <PrimaryButton
        size="small"
        type="button"
        onClick={() => setEditing(true)}
      >
        {editLabel}
      </PrimaryButton>
      <SlideInModal
        onCloseClicked={() => setEditing(false)}
        onOutsideClicked={() => setEditing(false)}
        isOpen={isEditing}
      >
        <FormContainer>
          {enableFiltering && (
            <div className="filter">
              <input
                value={filter}
                onChange={e => {
                  setFilter(e.target.value);
                }}
                type="text"
                className="input-field"
                placeholder="Filter"
              />
            </div>
          )}
          <div className={styles.optionsContainer}>
            <ul>{filteredOptions.map(option => renderOptions(option))}</ul>
            {filteredOptions.length === 0 && (
              <div className={styles.noResults}>No results found.</div>
            )}
          </div>
        </FormContainer>
      </SlideInModal>
    </div>
  );
};

export default MultiSelectField;
