import React from 'react';
import Circle from 'components/icons/circle';
import classnames from 'classnames';
import './styles.css';

const renderCircles = () => (
  <React.Fragment>
    <Circle />
    <Circle />
    <Circle />
  </React.Fragment>
);

const Loader = ({ type = 'default' }) => {
  return (
    <div
      className={classnames({ Loader: true, [`Loader--${type}`]: true })}
      role="progressbar"
    >
      {type === 'dots' && renderCircles()}
    </div>
  );
};

export default Loader;
